@import '@assets/scss/color.scss';

.btn {
  min-width: 120px;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 12px 0;
  border: none;
  border-radius: 4px;
  margin-left: auto;
  text-transform: uppercase;
  outline: none;

  &.disabled {
    opacity: 0.4;
    cursor: default;

    &:hover {
      opacity: 0.4;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &.main {
    color: $white-color;
    background-color: $blue-color;
    border: $blue-color 1px solid;
  }

  &.subMain {
    color: $black-color;
    background-color: $white-color;
    border: $gray-color 1px solid;
  }
}
