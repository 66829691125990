@use '@assets/scss/color';
@use '@assets/scss/variable';

@mixin columnSize {
    grid-template-columns: 80% 10% 10%;
}

.header {
    display: grid;
    font-size: 2rem;
    height: variable.$table_header_height_m;
    user-select: none;
    @include columnSize;

    .heading {
        color: color.$white-color;
        background-color: color.$header-color;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .sortIcon {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.heading,
.cell {
    border: 1px solid color.$cell-border-color;
    padding: 8px 4px;
}

.content {
    max-height: calc(
        100% - variable.$container_header_height_m -
            variable.$table_header_height_m
    );
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

.row {
    display: grid;

    @include columnSize;

    .cell {
        font-size: 1.6rem;

        .dateTime:last-child {
            display: inline-block;
            margin-left: 10px;
            padding-left: 10px;
            border-left: 1px solid color.$border-color;
        }

        i:hover {
            opacity: 0.8;
        }

        .hide {
            display: none;
        }
    }

    &.beingUpdated {
        color: color.$hightlight-text;

        input {
            color: color.$hightlight-text;
            outline: none;
        }
    }
}
