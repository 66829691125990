@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  padding-top: 16px;
}

.header {
  margin-bottom: 24px;
  text-transform: uppercase;
  color: color.$blue-color;
  letter-spacing: 1px;
}

.content {
  display: flex;
  justify-content: space-between;

  &Left {
    flex: 1;
  }

  &Right {
    width: 40%;
    margin-left: 12px;
  }
}

.form {
  border-radius: 4px;
  border: 2px solid color.$blue-color;
  padding: 24px 20px 24px;
  position: relative;

  &::before {
    content: attr(aftercontent);
    position: absolute;
    top: 0;
    left: 12px;
    font-size: 1.4rem;
    transform: translateY(-50%);
    color: color.$blue-color;
    display: block;
    background-color: color.$white-color;
    padding: 0 4px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &Input {
    border: none;
    outline: none;
    width: 100%;
    padding: 4px;
    font-size: 1.6rem;
    border-bottom: 1px solid color.$border-color;
  }

  &Action {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;

    &Item {
      min-width: 120px;
      font-size: 1.4rem;
      font-weight: 500;
      padding: 12px 0;
      color: #ffffff;
      background-color: color.$button-color;
      border: none;
      border-radius: 4px;
      text-transform: uppercase;
      outline: none;

      &:first-child {
        margin-right: 12px;
      }

      &:hover {
        opacity: 0.95;
      }

      &_LowPriority {
        background-color: unset;
        color: color.$black-color;

        &:hover {
          background-color: color.$btn-low-priority-background-color;
        }
      }

      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }
}

.btnIcon {
  margin-right: 8px;
}

@mixin column-template {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 2fr;
}

.content {
  &Left {
    border: 1px solid color.$border-color;
    border-radius: 4px;

    &Header {
      background-color: color.$light-blue-color;
      border-bottom: 1px solid color.$border-color;
      list-style: none;
      padding: 0;
      margin: 0;

      &Item {
        color: color.$blue-darker-color;
        font-weight: bold;
        text-align: center;
        padding: 8px 0;
        font-size: 1.6rem;
      }
    }

    &Body {
      padding: 0;
      margin: 0;
      max-height: calc(100vh - 80px - variable.$nav-height - 126px);
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      &Item {
        text-align: center;
        padding: 4px 0;

        &Text,
        .actionIcon {
          font-size: 1.4rem;
          font-weight: bold;
          color: color.$text-color;
          line-height: 1.4rem;
          padding: 8px 0;
        }

        .actionIcon {
          cursor: pointer;

          &:first-child {
            margin-right: 20px;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        &:nth-child(2n + 1) {
          background-color: color.$nearly-white-color;
        }

        &:not(:last-child) {
          border-bottom: 1px solid color.$border-color;
        }
      }
    }
  }
}

.row {
  @include column-template;
}
