@use '@assets/scss/color';

.wrapper {
    position: relative;
    margin-left: 32px;
    text-transform: uppercase;

    h3 {
        font-size: 1.6rem;
        padding: 8px 20px;
        border-radius: 4px;
        border: 1px solid color.$border-color;
        font-weight: 500;
        min-width: 142px;
        text-align: center;
        user-select: none;
    }

    .options {
        font-size: 1.6rem;
        min-width: 90px;
        border-radius: 8px;
        color: color.$list-text-color;
        background-color: color.$list-background-color;
        list-style: none;
        padding: 8px 0;
        position: absolute;
        top: 100%;
        right: 0;
        text-align: right;

        &:first {
            width: 80px;
        }

        .option {
            padding: 4px 16px;
            user-select: none;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
                background-color: color.$list-background-hover;
            }
        }

        &.hide {
            display: none;
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: color.$overlay-color;
    }

    .formInput {
        background-color: color.$input-background-color;
        margin: auto;
        z-index: 2;
        padding: 24px;
        border-radius: 4px;

        .input {
            border-radius: 4px;
            padding: 4px 8px;
            outline: none;
            border: 1px solid color.$border-color;
            font-size: 1.6rem;
        }
    }

    &.hide {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .wrapper {
        margin-left: 0;
    }
}
