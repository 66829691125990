@use '@assets/scss/color';

.wrapper {
    width: 210mm;
    height: 148mm;
    padding: 4mm;
    background-color: color.$white-color;
}

.header {
    display: flex;
    height: 24mm;

    .logoWrapper {
        display: flex;
        position: relative;
        margin-right: 60px;
        margin-left: auto;
        width: 10%;

        img {
            margin: auto 0 auto 0;
            max-height: 100%;
            object-fit: contain;
            filter: grayscale(100%);
        }
    }

    .contactHeader {
        color: color.$black-color;
        display: flex;
        min-width: 45%;
        flex-direction: column;
        justify-content: center;
        padding: 1.5mm 0;

        .companyName {
            font-size: 2.2rem;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        h4 {
            font-weight: 400;
            font-size: 1.4rem;
            margin-bottom: 0;

            .tax {
                letter-spacing: 1mm;
            }

            .hotline {
                font-weight: 500;
            }
        }

        p {
            font-size: 1.3rem;
            margin-bottom: 0;
        }

        .companyHotline {
            font-size: 1.6rem;
            margin-bottom: 0;
        }
    }
}

.titleHeader {
    flex: 1;
    margin-top: 6px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    align-self: center;
    font-size: 3.2rem;
    text-decoration: underline;
}

.inputVAT {
    display: inline-block;
    outline: none;
    border: none;
    padding: 0;
    width: 22px;
    text-align: center;
}

.receiver {
    font-size: 1.6rem;
    margin-bottom: 0;
    padding: 0 0 6px 8px;
    display: flex;
    flex-direction: column;

    .receiverHeader {
        text-decoration: underline;
        margin-right: 4px;
    }

    .receiverContent {
        display: inline-block;
        outline: none;
        flex: 1;
    }
}

@mixin columnSize {
    grid-template-columns: 4% 28% 12% 28% 28%;
}

.tableHeader,
.tableRow {
    display: grid;
    @include columnSize;
}

.tableContent {
    border: 2px solid color.$black-color;
    border-top: none;
}

.tableHeader {
    font-size: 1.4rem;
    border: 2px solid color.$black-color;
    border-bottom: none;
    color: color.$black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;

    .tableCell {
        padding: 2px;
        border-right: 1px solid color.$black-color;
        text-align: center;
    }

    .tableCell:last-child {
        border-right: none;
    }
}

.tableRow {
    font-size: 1.2rem;
    border-top: 1px solid color.$black-color;

    .tableCell {
        border: none;
        border-right: 1px solid color.$black-color;
        outline: none;
        padding: 0 4px;
        text-align: center;

        &:focus {
            border: 1px solid color.$border-color;
        }

        &.tableCellSpanThird {
            grid-column: 1 / 4;
            text-align: right;
        }

        &.tableCellSpanFourth {
            grid-column: 1 / 5;
            text-align: right;
        }

        &:last-child {
            border-right: none;
        }
    }
}

.footer {
    display: flex;
    margin-top: 8px;

    p {
        margin-bottom: 0;
    }

    .noteWrapper {
        flex: 1;
        max-width: 60%;

        .noteHeader {
            font-size: 1.2rem;
            font-weight: 500;
            text-decoration: underline;
            text-transform: uppercase;
        }

        .noteContent {
            font-size: 1rem;
            margin-left: 16px;
            padding: 2px;
            outline: none;

            &:focus {
                border: 1px solid color.$border-color;
            }
        }
    }

    .signWrapper {
        font-size: 1.2rem;
        width: 40%;
        text-align: center;
    }
}
