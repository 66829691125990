@use '@assets/scss/color';
@use '@assets/scss/variable';

.form {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;

  .inputWrapper {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    position: relative;
    margin-top: 4px;
    border-radius: 4px;

    input, select {
      border-radius: 4px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      width: calc(50% - 16px);
    }

    &:nth-child(3) {
      width: calc(90% - 16px);
    }

    .selectLabelWrapper {
      display: flex;
      align-items: center;

      .icon {
        padding: 0 8px;
        color: color.$label-icon-color;
        cursor: pointer;
      }
    }

    .label {
      color: color.$label-color;
      user-select: none;
    }

    .select,
    .input {
      flex: 1;
      padding: 2px 4px;
      font-size: 1.6rem;
      width: 100%;
      min-height: 30px;
      border: 1px solid color.$border-color;
      background-color: color.$input-background-color;
      outline: none;
    }

    &.error::before {
      content: '';
      position: absolute;
      top: 84%;
      left: 12px;
      border-style: solid;
      border-color: transparent transparent color.$error-background-color
        transparent;
      border-width: 8px;
      z-index: 1;
      animation: zoomOut ease-in-out 0.3s;
    }

    &.error::after {
      content: attr(error);
      position: absolute;
      top: 108%;
      left: 0;
      color: color.$error-text-color;
      background-color: color.$error-background-color;
      padding: 4px;
      border-radius: 4px;
      z-index: 1;
      animation: zoomOut ease-in-out 0.3s;
    }
  }
}

.btn {
  font-size: 1.6rem;
  padding: 0 24px;
  color: color.$btn-text-color;
  background-color: color.$btn-background-color;
  height: 30px;
  border: none;
  border-radius: 4px;
  outline: none;
  text-transform: uppercase;
  width: calc(10% - 16px);

  &:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 768px) {
  .form {
    position: absolute;
    top: calc(100% - 20px);
    background: white;
    z-index: 1;
    border-radius: 4px;
    height: 0;
    min-height: 0;
    visibility: hidden;
    box-shadow: 2px 2px 16px color.$shadow;
    padding: 16px;

    &.show {
      display: flex;
      visibility: visible;
      height: auto;

      & .inputWrapper {
        width: 100%;
      }

      .btn {
        margin-top: 16px;
        width: 100%;
      }
    }
  }
}
