@use '@assets/scss/color';
@use '@assets/scss/variable';

.containerHeader {
  height: variable.$container_header_height;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
}

.btn {
  font-size: 1.6rem;
  padding: 0 24px;
  margin-left: 16px;
  color: color.$btn-text-color;
  background-color: color.$btn-background-color;
  height: 30px;
  border: none;
  border-radius: 4px;
  outline: none;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }
}

.btnRefreshWrapper {
  margin-left: auto;
  margin-right: 8px;
  height: 100%;
  position: relative;

  &.newData::after {
    font-size: 1.4rem;
    content: attr(newdata);
    position: absolute;
    top: -40px;
    left: -64px;
    padding: 4px 16px;
    border-radius: 4px;
    color: color.$error-text-color;
    background-color: color.$error-background-color;
    animation: zoomOut ease-in-out 0.3s;
  }

  &.newData::before {
    content: '';
    position: absolute;
    top: -12px;
    left: 24px;
    border-width: 6px;
    border-style: solid;
    border-color: color.$error-background-color transparent
      transparent transparent;
    animation: zoomOut ease-in-out 0.3s;
  }

  .btn {
    height: 100%;

    &.disabled {
      opacity: 0.6;
    }
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  height: variable.$container_header_filter_height;
  margin-left: 24px;
  position: relative;
}

@keyframes zoomOut {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .filterContainer {
    height: unset;
    flex-direction: column;
    margin-left: 0;
  }

  .containerHeader {
    height: auto;
  }
}
