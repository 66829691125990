@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 16px;
  color: color.$blue-color;
  font-weight: bold;
}
