@use '@assets/scss/color';

.form {
    background-color: color.$white-color;
}

.formInputWrapper {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    padding: 0 16px;
    position: relative;

    .formLabel {
        font-size: 1.2rem;
        margin-right: 10px;
    }

    .formInput {
        font-size: 1.6rem;
        padding: 2px 4px;
        border-radius: 4px;
        outline: none;
        flex: 1;
        border: 1px solid color.$border-color;
    }

    .formSubmitBtn {
        font-size: 2rem;
        height: 40px;
        padding: 4px 0;
        border: none;
        border-radius: 4px;
        outline: none;
        color: color.$white-color;
        background-color: color.$blue-color;

        &:hover {
            opacity: 0.8;
        }
    }

    &.error::before {
        content: attr(error);
        position: absolute;
        top: 110%;
        left: 20px;
        padding: 4px 12px;
        font-size: 1.4rem;
        border-radius: 4px;
        color: color.$white-color;
        background-color: color.$blue-color;
        z-index: 1;
    }

    &.error::after {
        content: '';
        position: absolute;
        top: 84%;
        left: 30px;
        border-color: transparent transparent color.$blue-color
            transparent;
        border-style: solid;
        border-width: 6px;
        z-index: 1;
    }
}
