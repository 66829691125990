@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
    width: 100%;
    height: calc(100vh - 212px);
    max-height: calc(100vh - 212px);
}

.dayWrapper {
    display: inline-block;
    width: 30%;
    height: 28%;
    text-align: center;
    border: 2px solid color.$border-color;
    position: relative;
    margin-top: 16px;

    &:nth-child(3n + 2) {
        margin: 16px 16px 0;
    }

    &:focus-within {
        border: 2px solid color.$red-color;
    }

    user-select: none;
}

.imgDay {
    margin: 16px 0;
    min-height: calc(100% - 72px);
    height: calc(100% - 72px);
    max-width: 100%;
    object-fit: cover;
}

.dayOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: default;
    caret-color: transparent;
}

.dayHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    border-top: 2px solid color.$border-color;
    font-weight: bold;
    height: 40px;
}

.btnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.btn {
    font-size: 1.6rem;
    padding: 8px 12px;
    border: none;
    outline: none;
    color: color.$white-color;
    background-color: color.$blue-color;
    border-radius: 4px;

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.2;
    }
}
