@use '@assets/scss/color.scss';
@import '@assets/scss/media-query.scss';

.container {
  padding: 40px;

  @media #{$sp} {
    padding: 32px;
  }
}

.header {
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: color.$blue-color;
}

.order {
  display: flex;
  gap: 24px;
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  &Info {
    flex-basis: 300px;
    display: flex;
    flex-direction: column;
  }

  &Table {
    flex: 1;
  }

  &InfoText {
    text-transform: capitalize;
  }
}

.btn {
  display: flex;
  margin-left: auto;
  gap: 16px;
  
  &ChangeStatus {
    margin-top: 20px;
    width: 120px;
    text-transform: capitalize;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
