@use '@assets/scss/color';
@use '@assets/scss/variable';

.header {
  display: flex;
  align-items: center;
  height: variable.$container_header_height_m;
  margin: 0 16px;

  .datePickerLabel {
    font-size: 1.6rem;
    margin-right: 12px;
    text-transform: uppercase;
    color: color.$text-color;
    font-weight: bold;
  }

  .datePicker {
    padding: 4px 8px;
    font-size: 1.6rem;
    border-radius: 4px;
    outline: none;
    border: 1px solid color.$black-color;
  }

  .rightSide {
    display: flex;
    margin: 0 -8px;
    margin-left: auto;

    .total {
      display: flex;
      position: relative;
      padding: 0 16px;
      align-items: center;

      &Label {
        font-size: 2rem;
        margin-right: 4px;
        margin-bottom: 0;
        text-transform: capitalize;
        font-weight: bold;
      }

      &Value {
        display: inline-block;
        font-size: 1.8rem;
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        right: 0;
        height: 20px;
        border-right: 1px solid color.$border-color;
      }
    }
  }
}

.marketInfoTable {
  margin: 0 16px;
}

.actionItem {
  font-size: 2rem;
  padding: 4px 16px;
  min-width: 172px;
  text-transform: capitalize;
  font-weight: bold;
  border: none;
  outline: none;
  background-color: rgba(90, 90, 90, 0.95);
  color: color.$white-color;
  border-radius: 4px;
  margin-left: 12px;

  &:hover {
    background-color: rgb(50, 50, 50);
  }
}

.report {
  $padding-horizon: 28px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;

  &Picker {
    padding: 24px $padding-horizon;
    display: flex;
    flex-direction: column;

    &Label {
      font-size: 1.6rem;
      line-height: 1.6rem;
      font-weight: bold;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    &Month {
      font-size: 1.6rem;
      width: 100%;
      border: none;
      letter-spacing: 1px;
      border-bottom: 1px solid color.$border-color;
      outline: none;
    }
  }

  &Action {
    padding: 20px $padding-horizon;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgb(236, 236, 236);

    &Btn {
      margin-left: 16px;
      padding: 4px 20px;
      font-size: 1.4rem;
      font-weight: bold;
      border: none;
      letter-spacing: 1px;
      background-color: color.$blue-color;
      color: color.$white-color;
      border-radius: 4px;

      &_LowPrior {
        font-weight: normal;
        background-color: unset;
        color: color.$black-color;

        &:hover {
          background-color: rgb(224, 224, 224);
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    flex-direction: column;
    height: variable.$container_header_height_mobile_m;

    .rightSide {
      flex-direction: column;
      justify-content: center;
      margin: auto 0;

      .total {
        justify-content: center;
        margin: 4px 0;

        &Label {
          font-size: 1.6rem;
        }

        &Value {
          font-size: 1.4rem;
        }

        &:not(:last-child)::after {
          content: '';
          right: 0;
          left: 0;
          top: 100%;
          height: 0px;
          border-bottom: 1px solid color.$border-color;
          border-right: unset;
        }
      }
    }

    .actionItem {
      display: none;
    }
  }

  .marketInfoTable {
    margin: 0;
  }
}
