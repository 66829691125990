@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
  position: relative;
  margin: calc(40px + variable.$nav-height) 40px 0;
  height: calc(100vh - 180px);
  background-color: color.$content-container;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 16px color.$shadow;
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: unset;
    margin: calc(variable.$nav_sp_height) 0 0;
    height: calc(100vh - variable.$nav_sp_height);
  }
}
