@use '@assets/scss/color';
@use '@assets/scss/variable';

.noteFolder {
    margin: 10px;
    background-color: color.$note-folder-background-color;
    border-radius: 4px;
    max-height: calc(100vh - 92px - variable.$nav-height);
    padding: 8px;
    min-width: 280px;
    box-shadow: 1px 1px 8px color.$shadow;
    position: relative;
}

.noteHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    color: color.$note-heading-color;
    font-size: 1.8rem;
    width: 100%;

    .inputHeading {
        font-weight: 500;
        border: none;
        background: transparent;
        flex: 1;
        padding: 4px 8px;
        max-width: 240px;
        border: color.$new-note-input-border-color;
        cursor: pointer;

        &:focus {
            cursor: text;
        }
    }

    i {
        font-size: 1.6rem;
        padding: 4px;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
}

.notes {
    max-height: calc(100vh - 202px - variable.$nav-height);
    overflow-y: overlay;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: color.$note-folder-background-color;
    }

    &::-webkit-scrollbar-thumb {
        background: #827a8d;

        border-radius: 4px;

        &:hover {
            background: #bfb4ce;
        }
    }

    &.inputting {
        max-height: calc(100vh - 142px - variable.$nav-height);
    }
}

.newNoteTrigger {
    margin-top: 8px;
    font-size: 1.4rem;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;

    i {
        margin-right: 4px;
    }

    &:hover {
        background-color: rgb(218, 218, 218);
    }
}

.newNoteWrapper {
    margin: 8px 0;
    font-size: 1.4rem;
    border-radius: 4px;

    &:hover {
        background: color.$note-folder-background-color;
    }
}

.inputNewNote {
    width: 100%;
    min-height: 80px;
    max-height: 240px;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 8px 10px;
    border-radius: 4px;
    outline: none;
    border: none;
    box-shadow: 1px 1px 2px color.$shadow;

    &:focus {
        border: 1px solid color.$new-note-input-border-color;
    }
}

.addBtn {
    margin-top: 8px;
    font-size: 1.4rem;
    padding: 6px 10px;
    color: color.$white-color;
    background-color: color.$note-btn-background-color;
    border: none;
    border-radius: 4px;
}

.removeIcon {
    font-size: 1.6rem;
    padding: 8px;
    cursor: pointer;
}
