@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.row {
  display: grid;
  grid-template-columns: 200px 4fr 1fr;
}

$table_header_height: 40px;

.header {
  border: 1px solid color.$black-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: $table_header_height;

  .cell {
    background-color: color.$light-blue-color;
    color: color.$blue-darker-color;
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0 4px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &.cellWithIcon {
      position: relative;
      flex-direction: row;

      i {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        padding: 12px;
        transform: translateY(-50%);

        &:hover {
          opacity: 0.8;
        }
      }

      .filter {
        position: absolute;
        top: 100%;
        right: 12px;
        background-color: color.$white-color;
        padding: 20px;
        box-shadow: 1px 0px 2px color.$shadow;
        border: 1px solid color.$border-color;
        color: color.$black-color;
        text-transform: initial;
        display: none;

        &.show {
          display: block;
        }

        &List {
          list-style: none;
          margin: 0;
          padding: 0;
          align-items: flex-start;
          display: flex;
          flex-direction: column;
        }

        &Item {
          border-bottom: 1px solid color.$border-color;
          width: 100%;
          text-align: left;
          padding: 6px 8px 6px 0;

          label {
            margin-left: 8px;
            font-weight: 400;
          }
        }

        &Item:first-child {
          text-transform: uppercase;

          label {
            font-weight: 500;
          }
        }

        &Btn {
          margin-top: 16px;
          width: 100%;
          border: none;
          outline: none;
          color: white;
          background-color: color.$blue-color;
          font-size: 2rem;
        }
      }
    }

    &:first-child {
      align-items: flex-start;
      padding-left: 16px;
    }

    &:last-child,
    &:nth-last-child(3) {
      text-align: right;
      align-items: flex-end;
      padding-right: 16px;
    }

    &:not(:last-child) {
      border-right: 1px solid color.$black-color;
    }
  }
}

.body {
  max-height: calc(
    100vh -
      (
        variable.$nav_height + 40px + variable.$tabHeight +
          $table_header_height + variable.$container_header_height_m +
          variable.$container_footer_height
      )
  );
  overflow: auto;
  border-bottom: 1px solid color.$black-color;

  &::-webkit-scrollbar {
    display: none;
  }
}

.body {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: color.$black-color;

  &:nth-child(2n + 1) {
    background-color: color.$nearly-white-color;
  }

  .cell {
    font-size: 1.6rem;
    outline: none;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border: none;

    &.upperline {
      border-top: 1px solid color.$border-color;
    }

    &:first-child {
      align-items: flex-start;
      padding-left: 16px;
    }

    &:last-child,
    &:nth-last-child(3) {
      text-align: right;
      align-items: flex-end;
      padding-right: 16px;
    }

    &:not(:last-child) {
      border-right: 1px solid color.$black-color;
    }
  }

  .input {
    font-weight: bold;
    background-color: transparent;

    &.disabled {
      font-weight: normal;
      color: color.$black-color;

      &.shouldStyle {
        color: color.$light-gray-color;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .cell {
      font-size: 1.4rem;
    }
  }

  .body {
    max-height: calc(
      100vh -
        (
          variable.$nav_height + 40px + variable.$tabHeight +
            variable.$table_header_height +
            variable.$container_header_height_mobile_m +
            variable.$container_footer_height + 40px
        )
    );
  }
}
