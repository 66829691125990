.contentContainer {
  padding: 24px;
  height: unset;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: none;
}

.header {
  font-size: 24px;
}

.form {
  margin-top: 24px;
}
