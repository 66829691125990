/******************************************
*  Author : Nam Dinh   
*  Created On : Thu Apr 28 2022
*  File : PaginationBar.module.scss
*******************************************/

@use '@assets/scss/color.scss';

.paginationWrapper {
  // background-color: color.$pagination-background;
  // border: 1px solid color.$pagination-border;
  display: flex;
  justify-items: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 1px 2px color.$shadow;

  .page {
    color: color.$black-color;
    font-size: 1.4rem;
    padding: 2px 0;
    text-align: center;
    
    &.icon {
      cursor: pointer;
      flex: 1;

      &:hover {
        background-color: color.$light-gray-color;
      }

      &.disabled {
        pointer-events: none;
      }
    }

    &.icon {
      width: 40px;
      height: 80%;
      display: block;
      text-align: center;

      i {
        font-size: 1.4rem;
        line-height: 1.4rem;
        padding: 8px 12px;
      }

      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
