@use '@assets/scss/color';
@use '@assets/scss/variable';

.btn {
  font-size: 1.6rem;
  padding: 0 24px;
  margin-left: 16px;
  color: color.$btn-text-color;
  background-color: color.$btn-background-color;
  height: 30px;
  border: none;
  border-radius: 4px;
  outline: none;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: color.$overlay-color;
  }

  &.hide {
    display: none;
  }

  .formUpdate {
    margin: auto;
    background-color: color.$modal-background-color;
    z-index: 3;
    padding: 18px;
    padding-bottom: 32px;
    border-radius: 4px;
    display: flex;
    align-items: flex-end;

    .dateWrapper {
      display: flex;
      flex-direction: column;

      .datePickerLabel {
        font-size: 1.6rem;
      }

      .datePicker {
        padding: 4px 0;
        text-align: center;
        height: 100%;
        font-size: 2rem;
        width: 140px;
        border: 1px solid color.$border-color;
        border-radius: 4px;
      }
    }

    .btn {
      margin-top: 12px;
      height: 38px;
    }
  }
}
