@use '@assets/scss/color';

@media screen and (max-width: 768px) {
  .filterContainer {
    position: absolute;
    top: calc(100%);
    background-color: white;
    z-index: 2;
    left: 0;
    right: 0;
    align-items: center;
    box-shadow: 2px 2px 16px color.$shadow;
    flex-direction: column;
    border-radius: 4px;
    padding: 16px;
    overflow: hidden;
    visibility: hidden;
    height: 0;
    gap: 16px;

    & > div {
      width: 100%;
    }

    .select {
      width: 100%;
    }
  }

  .filterContainer.show {
    visibility: visible;
    height: auto;
  }
}

select.select {
  margin-right: 8px;
  padding: 2px 4px;
  font-size: 1.6rem;
  width: 400px;
  min-height: 30px;
  border: 1px solid color.$border-color;
  background-color: color.$input-background-color;
  outline: none;
  height: 100%;
}
