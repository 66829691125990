@use '@assets/scss/color';

.wrapper {
  width: 148mm;
  height: 210mm;
  padding: 4mm 8mm;
  background-color: color.$white-color;
}

.header {
  display: flex;
  height: 24mm;

  .logoWrapper {
    display: flex;
    position: relative;
    margin-right: 0;
    margin-left: auto;
    width: 24%;

    img {
      margin: auto 0;
      max-height: 100%;
      object-fit: contain;
      filter: grayscale(100%);
    }
  }

  .contactHeader {
    color: color.$black-color;
    display: flex;
    min-width: 45%;
    flex-direction: column;
    justify-content: center;
    padding: 1.5mm 0;

    .companyName {
      font-size: 2.2rem;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    h4 {
      font-weight: 400;
      font-size: 1.4rem;
      margin-bottom: 0;

      .tax {
        letter-spacing: 1mm;
      }

      .hotline {
        font-weight: 500;
      }
    }

    p {
      font-size: 1.3rem;
      margin-bottom: 0;
    }

    .companyHotline {
      font-size: 1.6rem;
      margin-bottom: 0;
    }
  }
}

.heading {
  position: relative;
  text-align: center;
  margin-bottom: 8px;

  .titleHeader {
    display: inline-block;
    margin-top: 6px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    align-self: center;
    font-size: 2.4rem;
  }

  .noHeader {
    position: absolute;
    margin-left: 8px;
    display: inline-block;
    font-size: 1.4rem;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400;

    span {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}

.inputVAT {
  display: inline-block;
  outline: none;
  border: none;
  padding: 0;
  width: 22px;
  text-align: center;
}

.receiver {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;

  .receiverHeader {
    text-decoration: underline;
    margin-right: 4px;
    font-weight: 500;
  }

  .receiverContent {
    display: inline-block;
    outline: none;
    flex: 1;
  }
}

@mixin columnSize {
  grid-template-columns: 52% 8% 20% 20%;
}

@mixin columnEqualSize {
  grid-template-columns: 30% 30% 20% 20%;
}

.tableHeader,
.tableRow {
  display: grid;
  @include columnSize;
}

.tableRowEqual {
  display: grid;
  @include columnEqualSize;
}

.tableHeader {
  margin: 0 4px;
}

.tableContent {
  border: 2px solid color.$black-color;
  border-top: none;
  margin: 0 4px;
}

.tableHeader {
  font-size: 1.4rem;
  border: 2px solid color.$black-color;
  border-bottom: none;
  color: color.$black-color;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;

  .tableCell {
    padding: 2px;
    border-right: 2px solid color.$black-color;
    text-align: center;
  }

  .tableCell:last-child {
    border-right: none;
  }
}

.tableRowEqual,
.tableRow {
  font-size: 1.2rem;
  border-top: 1px solid color.$black-color;

  &:first-child {
    border-top: 2px solid color.$black-color;
  }

  &.borderBoldTop {
    border-top: 2px solid color.$black-color;
  }

  &.borderNoneTop {
    border-top: none;

    .tableCell {
      &:not(.tableCellSpanThird) {
        border-top: 1px solid color.$black-color;
      }
    }
  }

  .tableCell {
    border: none;
    border-right: 2px solid color.$black-color;
    outline: none;
    padding: 0 4px;
    text-align: center;

    &:focus {
      border: 1px solid color.$border-color;
    }

    &.tableCellSpanThird {
      grid-column: 1 / 3;
      text-align: right;
    }

    &.tableCellSpanFourth {
      grid-column: 1 / 4;
      text-align: right;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.tableCellInput {
  display: inline-block;
  flex: 1;
  max-width: 100px;
  text-align: right;
  outline: none;
  border: none;
}

.footer {
  display: flex;
  margin-top: 25px;

  p {
    margin-bottom: 0;
  }

  .noteWrapper {
    flex: 1;
    max-width: 60%;

    .noteHeader {
      font-size: 1.2rem;
      font-weight: 500;
      text-decoration: underline;
      text-transform: uppercase;
    }

    .noteContent {
      font-size: 1rem;
      margin-left: 16px;
      padding: 2px;
      outline: none;

      &:focus {
        border: 1px solid color.$border-color;
      }
    }
  }

  .signWrapper {
    font-size: 1.2rem;
    width: 30%;
    text-align: center;
    font-weight: 500;

    &:last-child {
      flex: 1;
    }
  }
}
