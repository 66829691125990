@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  position: fixed;
  inset: 0;
  visibility: hidden;
  z-index: 1000;

  .overlay {
    position: absolute;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
    inset: 0;
    opacity: 0;
  }

  &.isOpen {
    visibility: visible;

    .overlay {
      opacity: 1;
    }
  }
}

.contentContainer {
  max-width: 100%;
  width: 960px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  background-color: color.$white-color;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  .container {
    transition: visibility 0.6s;

    .overlay {
      transition: opacity 0.6s;
    }
  }

  .contentContainer {
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    animation: slideUp 0.6s;

    &.slideDown {
      animation: slideDown 0.6s;
    }
  }
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  border-radius: 50%;
  color: color.$white-color;
  background-color: color.$blue-color;

  &:hover {
    opacity: 0.6;
  }
}

@keyframes slideUp {
  0% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
}
