@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
  display: flex;
  margin: calc(40px + variable.$nav-height) 40px 0;
  height: calc(100vh - variable.$nav-height - 80px);
  background-color: color.$container-background;

  .formWrapper,
  .tableWrapper {
    flex: 1;
  }

  .formWrapper {
    padding: 20px;
  }

  .tableWrapper {
    padding: 16px 16px 16px 0;

    .searchWrapper {
      display: flex;
      border: 1px solid color.$border-color;
      align-items: center;
      margin-bottom: 16px;
      font-size: 1.6rem;

      .searchBar {
        padding: 8px 0;
        padding-left: 4px;
        border-radius: 4px;
        outline: none;
        border: none;
        height: 100%;
        flex: 1;
      }

      i {
        font-size: 1.2rem;
        padding: 8px 16px;
        cursor: pointer;
      }
    }
  }
}

.group {
  max-height: calc(100vh - 480px);
  padding: 8px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.inputHeader {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.inputGroup {
  display: flex;
  font-size: 16px;
  margin-top: 12px;
  flex-direction: column;
  text-align: left;
  gap: 4px;

  .input {
    flex: 1;
  }
}
.inputCheckBoxGroup {
  text-align: left;
  font-size: 16px;
  margin-top: 12px;

  .checkBoxBtn {
    margin-right: 8px;
  }
}

.ecommerces {
  max-height: calc(
    100vh - variable.$nav-height - 80px - 50px - 18px - 32px - 183px - 33px -
      16px - 41px - 24px
  );
  overflow-y: auto;
}

.permissionLabel {
  display: block;
}

.permissionCheckbox {
  text-align: center;
}

.btn {
  margin-left: auto;

  &:disabled {
    opacity: 0.2;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    margin: variable.$nav_height 0 0;
    height: calc(100vh - variable.$nav_height);
    flex-direction: column;

    .tableWrapper {
      padding: 16px;
    }
  }
}
