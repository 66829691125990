@use '@assets/scss/color.scss';

.page {
    color: color.$black-color;
    font-size: 1.4rem;
    padding: 2px 0;

    &:not(:nth-last-child(2)) {
        margin-right: 8px;
    }

    .pageText {
        display: block;
        padding: 8px 12px;
        border-radius: 4px;
        font-weight: 700;
        text-align: center;

        &.pageSelected {
            background-color: color.$blue-color;
            color: color.$white-color;
        }
    }

    .pageText {
        cursor: pointer;  

        &:not(.pageSelected):hover {
            height: 100%;
            background-color: color.$light-gray-color;
        }
    }
}
