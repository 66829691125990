@use '@assets/scss/color';
@use '@assets/scss/variable';

@mixin columnSize {
    grid-template-columns: 18% 24% 14% 28% 8% 8%;
}

.tableCell {
    border: 1px solid color.$cell-border-color;
    padding: 4px;
    text-align: center;
}

.tableHeader {
    display: grid;
    @include columnSize;

    .tableCell {
        text-transform: uppercase;
        font-size: 2rem;
        color: color.$header-text;
        background-color: color.$header-color;
    }
}

.tableContent {
    max-height: calc(100% - 90px);
    overflow-y: scroll;
    border-bottom: 1px solid color.$table-content-border-color;
    &::-webkit-scrollbar {
        display: none;
    }

    .tableRow {
        display: grid;
        @include columnSize;

        &.selectedRow {
            color: color.$hightlight-text;
        }

        &.isDeletedRow {
            color: color.$ignore-text-color;

            .restoreIconWrapper i {
                color: color.$black-color;
            }
        }

        .tableCell {
            font-size: 1.6rem;
        }
    }
}

@media screen and (max-width: 1024px) {
    @mixin columnSize {
        grid-template-columns: 18% 24% 14% 28% 8% 8%;
    }

    .tableHeader,
    .tableRow {
        @include columnSize;
    }

    .tableRow {
        .tableCell {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
        }
    }
}
