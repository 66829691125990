@use './color';

@mixin arrowErrorBox {
    content: '';
    position: absolute;
    top: 16px;
    border-color: transparent transparent color.$error-background-color
        transparent;
    border-width: 12px;
    left: 140px;
    border-style: solid;
    animation: fadeIn ease-in 0.2s;
}

@mixin errorBox {
    content: attr(error);
    min-width: 180px;
    position: absolute;
    color: color.$error-text-color;
    font-size: 1.4rem;
    padding: 0 8px;
    border-radius: 4px;
    top: 32px;
    left: 140px;
    background-color: color.$error-background-color;
    line-height: 32px;
    height: 32px;
    transform-origin: left top;
    animation: fadeIn ease-in 0.2s;
}
