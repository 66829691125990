@use '@assets/scss/color';
@use '@assets/scss/error';
@use '@assets/scss/variable';

.keywordContainer {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  transform: translateY(-100%);
  display: inline-block;
  flex: 1;
}

.toggleButton {
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: color.$blue-color;
  padding: 4px 12px;
  color: white;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  i {
    margin-left: 4px;
  }

  &.topRadiusOnly {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.keywordModal {
  position: absolute;
  top: 100%;
  right: 0;
  width: 400px;
  background-color: color.$blue-color;
  padding: 8px 16px 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-end;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  select {
    width: 100%;
    font-size: 16px;
    outline: none;
    border-radius: 4px;
    padding: 0 4px;
  }

  label {
    min-width: unset;
    width: unset;
  }

  .keywordInputContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .keywordInput {
    flex: 1;
    border-radius: 4px;

    input {
      border-radius: 4px;
      padding: 0 4px;
      margin-right: 0;
    }
  }

  .addKeywordBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color.$red-color;
    color: color.$white-color;
    padding: 8px;
    font-size: 14px;
    border-radius: 50%;
  }

  p {
    color: color.$red-color;
    margin-left: 80px;
    font-size: 16px;
    font-weight: 700;
    align-self: flex-start;
  }
}

.currentKeywordsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;

  .currentKeyword {
    display: flex;
    gap: 8px;
    background-color: color.$white-color;
    box-shadow: 1px 1p 4px color.$shadow;
    align-items: center;
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 16px;
    border: 1px solid #ccc;

    i {
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: color.$red-color;
        opacity: 0.8;
      }
    }
  }
}

.hidden {
  display: none;
}
