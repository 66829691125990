@use '@assets/scss/color';

.statistic {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;

  .statisticContent {
    &:nth-child(2) {
      border-left: 1px solid color.$border-color;
      margin-left: 32px;
      padding-left: 32px;
    }

    .statisticLabel {
      text-decoration: underline;
      display: inline-block;
      text-transform: uppercase;
    }

    .statisticValue {
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .statistic {
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;

    .statisticContent {
      display: flex;
      
      &:nth-child(2) {
        border: none;
      }

      .statisticLabel {
        display: block;
      }
    }
  }
}
