@use '@assets/scss/color';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 148mm;
  height: 210mm;
  padding: 0 4mm;
  background-color: color.$white-color;
}

.header {
  margin: 16px 0;
  img {
    width: 100%;
    object-fit: contain;
  }
  .logoWrapper {
    display: flex;
    position: relative;
    margin-right: 60px;
    margin-left: auto;
    width: 10%;

    img {
      margin: auto 0 auto 0;
      max-height: 100%;
      object-fit: contain;
      filter: grayscale(100%);
    }
  }
}

.titleHeader {
  margin-top: 12px;
  position: relative;
  flex: 1;
  margin-top: 6px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  align-self: center;
  font-size: 24px;
  text-decoration: underline;

  span {
    font-size: 14px;
    text-transform: none;
    text-decoration: none;
    position: absolute;
    top: 6px;
    left: calc(50% + 132px);
  }
}

.content {
  margin: 8px 24px;
}

.receiver {
  font-size: 14px;
  display: flex;
  flex-direction: column;

  .receiverHeader {
    display: inline-block;
    text-decoration: underline;
    width: 92px;
    margin-right: 4px;
  }

  .receiverContent {
    display: inline-block;
    outline: none;
    flex: 1;
  }
}

@mixin columnSize {
  grid-template-columns: 50% 10% 20% 20%;
}

.tableHeader,
.tableRow {
  display: grid;
  @include columnSize;
}

.tableContent {
  border: 2px solid color.$black-color;
  border-top: none;
}

.tableHeader {
  margin-top: 24px;
  font-size: 1.6rem;
  border: 2px solid color.$black-color;
  color: color.$black-color;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;

  .tableCell {
    padding: 2px;
    text-align: center;

    &:not(:first-child) {
      border-left: 2px solid color.$black-color;
    }
  }
}

.inputVAT {
  display: inline-block;
  outline: none;
  border: none;
  padding: 0;
  width: 22px;
  text-align: center;
}

.tableRow {
  font-size: 1.2rem;

  &.tablePriceRow {
    grid-template-columns: 30% 30% 20% 20%;

    & .tableCell {
      border-top: 2px solid color.$black-color;
      border-bottom: 2px solid color.$black-color;
    }
  }

  &.priceSection {
    font-weight: bold;

    &:nth-child(11) {
      .tableCell {
        border-top: 2px solid color.$black-color;
      }
    }

    &:nth-child(14) {
      .tableCell {
        border-top: none;
      }
    }
  }

  &:not(:first-child) {
    .tableCell {
      border-top: 1px solid color.$black-color;
    }
  }

  .tableCell {
    border: none;
    outline: none;
    text-align: center;

    &:not(:first-child) {
      border-left: 2px solid color.$black-color;
    }

    &.tableCellSpanTwo {
      border-top: none;
    }

    &.priceWithLabel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      overflow: hidden;

      & > div {
        min-width: 40px;
      }

      input {
        border: none;
        outline: none;
        text-align: right;
        flex: 1;
        max-width: calc(100% - 48px);
      }
    }

    &:focus {
      border: 1px solid color.$border-color;
    }

    &.tableCellSpanTwo {
      grid-column: 1 / 3;
    }

    &.tableCellSpanThird {
      grid-column: 1 / 3;
      text-align: right;
    }

    &.tableCellSpanFourth {
      grid-column: 1 / 4;
      text-align: right;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-around;
  margin-top: 24px;

  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 12px;
  }

  .signWrapper {
    font-weight: bold;
    font-size: 12px;
    width: 40%;
    text-align: center;
  }
}
