@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
    margin: calc(40px + variable.$nav-height) 40px 0;
    background-color: color.$content-container;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 2px 2px 16px color.$shadow;
    height: calc(100vh - 180px);
}

.containerHeader {
    display: flex;
    align-items: center;
    padding: 16px;
}

.datePickerWrapper {
    display: flex;
    align-items: center;
}

.datePickerLabel {
    font-size: 1.6rem;
    font-weight: 500;
    margin-right: 8px;
    text-transform: uppercase;
}

.profitTotal {
    margin-left: auto;
    color: color.$blue-color;
}

.btn {
    margin-left: auto;
    font-size: 2rem;
    padding: 8px 12px;
    outline: none;
    border: none;
    border-radius: 4px;
    color: color.$btn-text-color;
    background-color: color.$btn-background-color;
    text-transform: uppercase;

    &.link {
        text-decoration: none;
    }

    &:hover {
        opacity: 0.8;
        color: color.$white-color;
    }
}

.containerContent {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    max-height: calc(100% - 80px);

    .pieWrapper {
        max-width: 100%;
        width: 720px;
    }

    .selectType,
    .btn {
        margin-left: 0;
        height: 46px;
        width: 242px;
    }

    .filter {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .selectType {
            align-self: flex-end;
            font-size: 1.6rem;
            outline: none;
            text-transform: uppercase;
            margin-bottom: 8px;
            background-color: color.$input-background-color;
            padding: 4px;
            border: 1px solid color.$border-color;
            border-radius: 4px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .container {
        margin: 40px 0;
        height: unset;
    }

    .datePickerWrapper {
        flex-direction: column;
    }
    
    .containerHeader {
        flex-direction: column-reverse;

        .btn {
            margin-left: 0;
            margin-bottom: 20px;
        }
    }

    .containerContent {
        flex-direction: column-reverse;
        max-height: unset;
        align-items: center;

        .btn {
            margin: 20px 0;
            text-align: center;
        }
    }
}
