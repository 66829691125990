@use '@assets/scss/color';

.filterDate {
    font-size: 1.6rem;
    padding: 4px 16px;
    border: 1px solid color.$border-color;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .options {
        list-style: none;
        background-color: color.$white-color;
        padding-left: 0;
        position: absolute;
        top: 42px;
        left: 0px;
        z-index: 1;
        border: 1px solid color.$shadow;
        border-radius: 6px;
        margin-bottom: 0;
        box-shadow: 1px 1px color.$shadow;
        overflow-y: scroll;
        max-height: 220px;

        &::-webkit-scrollbar {
            display: none;
        }

        &.hide {
            display: none;
        }

        .option {
            font-size: 1.6rem;
            padding: 8px 16px;
            cursor: pointer;
            border-bottom: 1px solid color.$border-color;
            user-select: none;

            &:hover {
                background-color: color.$blue-color;
                color: color.$white-color;
                opacity: 0.8;
            }

            &::before {
                content: '';
                position: absolute;
                top: -16px;
                border-style: solid;
                border-color: transparent transparent
                    color.$near-white-color transparent;
                border-width: 8px 6px;
            }
        }
    }
}

#modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;

    &.hide {
        display: none;
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: color.$overlay-color;
    }

    .modalBody {
        position: relative;
        margin: auto;
        background-color: color.$white-color;
        padding: 20px;
        height: 136px;
        border-radius: 8px;
        display: flex;
        width: 720px;
        justify-content: space-between;
        border: 1px solid color.$border-color;
        box-shadow: 1px 1px color.$black-color;

        .dateWrapper {
            display: flex;
            flex-direction: column;

            & .datePickerLabel {
                font-size: 2rem;
                color: rgba(0, 0, 0, 0.4);
                margin-left: 4px;
            }

            & .datePicker {
                font-size: 2.4rem;
                padding: 4px;
                text-align: center;
                outline: none;
                border: 1px solid color.$shadow;
                width: 320px;
                border-radius: 4px;
                position: relative;

                &:hover {
                    opacity: 0.6;
                }

                &::after {
                    content: '';
                    
                }
            }

            .error {
                font-size: 1.6rem;
                color: color.$red-color;
            }
        }
    }
}
