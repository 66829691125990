@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
  margin-top: 4px;
  font-size: 14px;
}
.selected {
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  padding: 4px;
}
.choices {
  border-top: 1px solid color.$border-color;
  padding: 8px 4px 4px;

  &.hide {
    display: none;
  }
}
.checkBoxWrapper {
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 4px;
  }
}
.label {
  margin-left: 8px;
}
