@use '@assets/scss/color';
@use '@assets/scss/error';
@use '@assets/scss/variable';

.wrapper {
  font-size: 1.6rem;
  display: flex;

  table {
    margin: 0;
  }

  &.sideBarShown {
    width: 75%;
  }
}

.mainContent {
  flex: 1;

  & .tableWrapper {
    background-color: color.$white-color;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 2px 20px color.$shadow;

    &.fullSize {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
    }
  }
}

.actionBar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 40px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .actionBar {
    margin: 0 0 16px 0;
  }
}
