@use '@assets/scss/color';
@use '@assets/scss/variable';

@mixin columnSize-8 {
  grid-template-columns: 4% 10% 10% 30% 8% 30% 4% 4%;
}

@mixin columnSize-7 {
  grid-template-columns: 4% 10% 10% 30% 8% 30% 8%;
}

.header {
  display: grid;
  font-size: 2rem;
  height: variable.$table_header_height;
  user-select: none;
  @include columnSize-7;

  &.full {
    @include columnSize-8;
  }
  .heading {
    color: color.$white-color;
    background-color: color.$header-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .sortIcon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.heading,
.cell {
  border: 1px solid color.$cell-border-color;
  padding: 8px 4px;
  color: color.$black-color;

  &:hover i {
    opacity: 0.8;
  }
}

input.cell {
  color: color.$red-color;

  &:disabled {
    color: color.$black-color;
  }
}

.content {
  max-height: calc(
    100% - variable.$container_header_height - variable.$table_header_height
  );
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}
.row {
  display: grid;

  @include columnSize-7;

  &.full {
    @include columnSize-8;
  }

  .cell {
    font-size: 1.6rem;

    .dateTime:last-child {
      display: inline-block;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid color.$border-color;
    }
  }
}

@media screen and (max-width: 768px) {
  @mixin columnSize {
    grid-template-columns: 16% 28% 30% 16% 10%;
  }

  .header,
  .row,
  .header.full,
  .row.full {
    font-size: 1.4rem;
    @include columnSize;

    .heading,
    .cell {
      word-wrap: break-word;

      .sortIcon {
        display: none;
      }

      &:nth-child(1),
      &:nth-child(6),
      &:nth-child(7) {
        display: none;
      }

      .dateTime:last-child {
        display: block;
        border-left: none;
        margin-left: 0;
        padding-left: 0;
        align-self: center;
      }
    }
  }
}
