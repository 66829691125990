@use '@assets/scss/color';

.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textLeftFlex {
  justify-content: flex-start !important;
}

.flex {
  display: flex;
}

.flexStretch {
  flex: 1;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.alignSelfCenter {
  align-self: center !important;
}

.alignSelfUnset {
  align-self: unset !important;
}

// grid 2 columns
.grid-2 {
  display: grid !important;
  grid-template-columns: auto auto !important;
  gap: 8px !important;
}

.grid-7-3 {
  display: grid !important;
  grid-template-columns: 70% auto !important;
  gap: 8px !important;
}

.w-100 {
  width: 100% !important;
}

.textRed {
  color: color.$red-color !important;
}

.textUnselect {
  user-select: none !important;
}

.textUppercase {
  text-transform: uppercase !important;
}

.textBold {
  font-weight: 500 !important;
}

.textUnderline {
  text-decoration: underline;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.clickable {
  cursor: pointer !important;
}

.textLeft {
  text-align: left;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: 30px !important;
  width: 35px !important;
  font-size: 1.4rem !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.6rem !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle {
  transform: translate(20px, 0px) !important;
}

.react-datepicker-wrapper,
.react-datepicker__month-wrapper {
  width: unset !important;
}

.react-datepicker__month-text {
  font-size: 1.4rem !important;
  width: 48px !important;
  line-height: 30px !important;
}

.react-datepicker__navigation-icon::before {
  top: 50% !important;
  transform: translateX(-50%);
}

.react-datepicker__navigation-icon--previous::before {
  right: 0 !important;
}

.react-datepicker__navigation-icon--next::before {
  left: 2px !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  color: color.$red-color !important;
}

.react-datepicker__day--selected,
.react-datepicker__month--selected {
  background-color: color.$body-background !important;
  color: color.$white-color !important;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.lds-ring {
  position: relative;
  width: 40px;
  height: 40px;
  display: none;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 4px solid color.$blue-color;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: color.$blue-color transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.2s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.1s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-toast {
  width: 25em;
  font-size: 1.6rem;

  .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2em;
  }

  .p-toast-message .p-toast-icon-close {
    width: 2em;
    height: 2em;
  }

  .p-link {
    font-size: 1em;
  }
}

.pi {
  font-size: 1em;
}

.p-dropdown {
  font-size: 1.6rem;

  .p-dropdown-trigger {
    width: 3em;
  }
}

.p-inputtext {
  padding: 0.75em 0.75em;
  font-size: 1em;
}

.p-component {
  font-size: 1.6rem;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.75em 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.75em 1.25em;
}

.p-float-label > label {
  left: 0.75em;
  margin-top: -0.5em;
  font-size: 1.6rem;
}

.text-red-400 {
  color: var(--red-400);
}

.card {
  background: var(--surface-card);
  border-radius: 10px;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.field {
  margin-bottom: 1em;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

.hidden {
  display: none !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Custom Date Picker Primereact
.p-datepicker table {
  font-size: 1.6rem;
}

.p-link {
  font-size: 1.6rem;
}

.isPC {
  display: block;

  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
