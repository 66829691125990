@use '@assets/scss/color';
@use '@assets/scss/variable';

.picker {
    display: flex;
    flex-direction: column;

    &Label {
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: bold;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    &Month {
        font-size: 1.6rem;
        width: 100%;
        border: none;
        letter-spacing: 1px;
        border-bottom: 1px solid color.$border-color;
        outline: none;
    }
}
