@use '@assets/scss/color';

.sideBarContainer {
    position: fixed;
    background-color: color.$white-color;
    right: 0;
    bottom: 0;
    top: 0;
    width: 25%;
    border-left: 1px solid color.$border-color;
    padding: 0 20px;
    z-index: 101;
}

.closeBtn {
    font-size: 2.8rem;
    position: absolute;
    right: 0;
    padding: 20px;
    cursor: pointer;
    z-index: 2;

    &:hover {
        opacity: 0.6;
    }
}

.hidden {
    display: none;
}

.wrapperContent {
    font-size: 1.6rem;
    margin-top: 16px;
    display: flex;
    align-items: center;
    min-height: 40px;
    position: relative;

    .labelContent {
        width: 120px;
        text-align: right;
        margin-right: 12px;
        line-height: 1;
    }

    .content {
        display: inline-block;
        font-weight: 400;
        margin-bottom: 0;
    }

    .inputContent {
        padding: 4px;
        border: none;
        border-bottom: 1px solid color.$border-color;
        outline: none;
        flex: 1;
    }

    .selectContent {
        padding: 4px;
        flex: 1;
        background-color: color.$white-color;
        border: 1px solid color.$border-color;
        outline: none;
    }

    .img {
        padding: 20px;
        width: 80%;
        margin: auto;
        object-fit: contain;
    }

    &.error::after {
        content: attr(error);
        position: absolute;
        top: 100%;
        left: 120px;
        padding: 2px 8px;
        color: color.$white-color;
        background-color: color.$blue-color;
        border-radius: 4px;
        animation: moveDown ease-in-out 0.2s;
        z-index: 1;
    }

    &.error::before {
        content: '';
        position: absolute;
        top: 64%;
        left: 128px;
        border-style: solid;
        border-width: 8px 6px;
        border-color: transparent transparent color.$blue-color
            transparent;
        animation: moveDown ease-in-out 0.2s;
        z-index: 1;
    }

    @keyframes moveDown {
        from {
            opacity: 0;
            top: 80%;
        }
        to {
            opacity: 1;
            top: 100%;
        }
    }
}

.phoneList {
    display: flex;
    flex-wrap: wrap;

    .phoneWrapper {
        margin: 0 4px;
        font-size: 1.4rem;
        height: 24px;
        line-height: 24px;
        align-self: center;
        max-width: 110px;
        position: relative;
        background-color: color.$box-color;
        border: none;
        border-radius: 2px;
        user-select: none;
        cursor: default;
        margin-top: 4px;

        & .phoneBox {
            color: color.$white-color;
            flex: 1;
            max-width: 84px;
            height: 20px;
            line-height: 24px;
            background-color: color.$box-color;
            border: none;
            outline: none;
            margin-left: 4px;
            display: inline-block;
        }

        & .phoneRemove {
            font-size: 1.2rem;
            color: color.$white-color;
            padding: 0 8px;

            &:hover {
                opacity: 0.6;
            }

            cursor: pointer;
        }
    }
}

.footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0px;
    display: flex;
    justify-content: space-between;

    .btn {
        flex: 1;
        margin: 0 20px;
        height: 40px;
        background-color: color.$blue-color;
        color: color.$white-color;
        font-size: 2rem;
        padding: 4px 0;
        outline: none;
        border: none;
        text-transform: uppercase;

        &:hover {
            opacity: 0.8;
        }

        &.hide {
            display: none;
        }
    }
}
