@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
    margin: 0 20px 0 20px;
    padding-top: calc(40px + variable.$nav-height);
    height: calc(100vh - 10px);
    scroll-behavior: smooth;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        height: 12px;
        bottom: 2px;
    }

    &::-webkit-scrollbar-track {
        background: color.$black-color;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 4px;

        &:hover {
            background: rgba(255, 255, 255, 0.3);
        }
    }
}

.noteFolders {
    display: flex;
    margin: 0 -10px;
    list-style: none;
    padding: 0;
    align-items: flex-start;
}

.newNoteFolder {
    margin: 10px;
    background-color: color.$new-note-folder-background-color;
    border-radius: 4px;
    padding: 4px;
    min-width: 280px;
    box-shadow: 1px 1px 8px color.$shadow;

    &.inInputting {
        background-color: color.$note-folder-background-color;

        &:hover {
            background-color: color.$note-folder-background-color;
        }
    }

    &:hover {
        background-color: #dadada;
    }
}

.newNoteFolderTrigger {
    font-size: 1.4rem;
    color: color.$note-text-color;
    padding: 8px 12px;
    cursor: pointer;

    i {
        margin-right: 8px;
    }
}

.inputNewNoteFolder {
    width: 100%;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 8px 10px;
    border-radius: 4px;
    outline: none;
    border: 2px solid color.$new-note-input-background-color;
    box-shadow: 1px 1px 2px color.$shadow;
}

.addBtn {
    margin-top: 8px;
    font-size: 1.4rem;
    padding: 6px 10px;
    color: color.$white-color;
    background-color: color.$note-btn-background-color;
    border: none;
    border-radius: 4px;
}

.removeIcon {
    font-size: 1.6rem;
    padding: 8px;
    cursor: pointer;
}

