@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  z-index: 3;
  margin: auto;
  background-color: color.$white-color;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid color.$light-gray-color;
  box-shadow: 2px 2px 4px color.$shadow;

  .heading {
    font-size: 2rem;
    text-transform: uppercase;
    color: color.$blue-darker-color;
    font-weight: bold;
  }
}

.invoiceInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &Cell {
    margin-top: 16px;
    position: relative;
    width: calc(50% - 16px);
    display: flex;
    align-items: center;
    color: color.$gray-color;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      display: block;
      border-bottom: 1px solid color.$border-color;
    }

    .label {
      font-size: 1.6rem;
      width: 30%;
      margin-right: 4px;
      font-weight: bold;
    }

    .value {
      flex: 1;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: right;

      select {
        outline: none;
        border: none;
        padding: 4px 8px;
        background-color: color.$white-color;
      }

      p {
        margin: 0;

        &:last-child {
          font-size: 1.4rem;
          font-weight: normal;
        }
      }
    }
  }
}

.btns {
  margin-top: 16px;
  text-align: right;

  .btn {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 8px 16px;
    border: none;
    outline: none;
    background-color: color.$blue-color;
    color: color.$white-color;
    border-radius: 4px;
    text-transform: uppercase;

    &:disabled {
      opacity: 0.4;
    }
  }
}

.invoiceDetailInfo {
  margin-top: 16px;
  border: 1px solid color.$border-color;
}

.row {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 2fr 3fr;
  font-size: 1.4rem;
  text-align: center;

  .cell {
    padding: 8px 0;
    font-weight: bold;
    color: color.$gray-color;
  }
}

.header {
  &.row {
    background-color: color.$light-blue-color;
    border-bottom: 1px solid color.$border-color;
  }

  .cell {
    color: color.$blue-darker-color;
  }
}

.body {
  max-height: calc(100vh - 300px);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .row:nth-child(2n + 1) {
    background-color: color.$nearly-white-color;
  }
}
