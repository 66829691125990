@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  padding: 16px 0;
  height: 100%;
  display: flex;
  gap: 16px;
}

.invoice {
  flex: 1;
  border: 1px solid color.$border-color;
  box-shadow: 1px 1px 4px color.$shadow;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  &CreatedBy {
    font-size: 1.6rem;
    border-bottom: 1px solid color.$border-color;
    padding: 4px 8px;
    color: color.$black-color;
    background-color: color.$light-gray-color;
    pointer-events: none;

    span {
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  &List {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .table {
    flex: 1;

    .row {
      display: grid;
      grid-template-columns: 4% auto 15% 20% 20%;

      .cell {
        padding: 4px 8px;
        text-align: center;
      }
    }

    &Header {
      font-size: 1.6rem;
      background-color: color.$light-blue-color;
      border-bottom: 1px solid color.$border-color;
      font-weight: bold;
      color: color.$blue-darker-color;
    }

    &Body {
      .row {
        font-size: 1.6rem;

        .cell {
          border: none;
          background-color: transparent;  
          outline: none;

          i {
            cursor: pointer;
            padding: 4px 0;
          }

          &:focus {
            border: 1px solid color.$border-color;
          }
        }

        &:nth-child(2n) {
          background-color: color.$light-gray-color;
        }
      }
    }
  }

  &InTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-top: 1px solid color.$border-color;
    font-size: 1.4rem;
    color: color.$blue-darker-color;

    &Label {
      font-size: 1.4rem;
      margin: 0;
      text-transform: capitalize;
      letter-spacing: 0.5px;
    }

    &Value {
      font-weight: bold;
    }
  }
}

.require {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &Supplier {
    width: 100%;
    position: relative;

    &Selected {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      line-height: 1.6;
      width: 100%;
      border: 1px solid color.$border-color;
      padding: 4px 8px;
      border-radius: 4px;
      box-shadow: 0 0 3px color.$shadow;
      overflow: hidden;

      &Text {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        user-select: none;
      }

      &Icon {
        margin-left: 12px;
        transform: rotate(0);
        transition: transform 0.3s ease-in-out;

        &.rotate {
          transform: rotate(90deg);
        }
      }
    }

    &Selection {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: calc(100% + 16px);
      left: -80px;
      right: 1px;
      z-index: 20;
      background-color: color.$white-color;
      border: 1px solid color.$border-color;
      border-radius: 4px;
      box-shadow: 1px 1px 4px color.$shadow;
      padding: 16px;
      transform-origin: 100% 0;
      animation: fadeIn 0.3s ease-in-out forwards;

      &.close {
        animation: fadeOut 0.3s ease-in-out forwards;
      }

      &::after {
        content: '';
        position: absolute;
        top: -16px;
        right: 8px;
        border-color: transparent transparent color.$light-gray-color
          transparent;
        border-width: 8px 6px;
        border-style: solid;
      }
    }

    &Search {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border: 1px solid color.$border-color;
      border-radius: 4px;
      font-size: 1.6rem;

      &Input {
        flex: 1;
        border: none;
        outline: none;
      }

      &Icon {
        margin-left: 8px;
      }
    }

    &Items {
      width: 100%;
      margin: 16px 0;
    }

    &Item {
      font-size: 1.4rem;
      color: color.$text-color;
      border: 1px solid color.$border-color;
      border-radius: 4px;
      padding: 4px 8px;
      cursor: pointer;
      user-select: none;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      h3,
      h4 {
        margin: 0;
      }

      h4 {
        margin-top: 4px;
        font-weight: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &Product {
    width: 100%;
    overflow: hidden;
    border: 1px solid color.$border-color;
    box-shadow: 1px 1px 4px color.$shadow;

    &:focus-within {
      border-width: 2px;
    }

    &Search {
      border-bottom: 1px solid color.$border-color;
      display: flex;
      align-items: center;
      font-size: 1.6rem;

      &Input {
        flex: 1;
        padding: 4px 8px;
        border: none;
        outline: none;
      }

      &Icon {
        padding: 8px;
      }
    }

    &List {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &Items {
      padding: 8px;
      width: 100%;
      height: calc(
        100vh - 80px - variable.$nav_height - 32px - 36px - 16px -
          32px - 164px
      );
      max-height: calc(
        100vh - 80px - variable.$nav_height - 32px - 36px - 16px -
          32px - 164px
      );
      overflow: auto;
      font-size: 1.2rem;
      line-height: 1.6;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &Item {
      border: 1px solid color.$border-color;
      display: flex;
      padding: 4px 8px;
      color: color.$text-color;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      cursor: pointer;

      &:hover {
        border-color: color.$blue-color;
        border-width: 2px;
        color: color.$blue-color;

        .requireProductItemAdd {
          border-color: color.$blue-color;
          color: color.$blue-color;
        }
      }

      h4 {
        font-weight: normal;

        span {
          display: inline-block;
          margin: 0 4px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &Add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid color.$gray-color;
        color: color.$gray-color;
        background-color: transparent;
        color: none;
      }
    }
  }

  &Status {
    width: 100%;

    &Items {
      width: 100%;
      font-size: 1.6rem;
      padding: 4px;
    }
  }

  .btns {
    width: 100%;
  }

  .saveBtn {
    width: 100%;
    padding: 8px;
    font-size: 1.6rem;
    outline: none;
    border: none;
    color: color.$white-color;
    background-color: color.$blue-color;
    border-radius: 4px;
    text-transform: uppercase;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
    visibility: hidden;
  }

  100% {
    opacity: 1;
    transform: scale(100%);
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(100%);
    visibility: visible;
  }

  100% {
    opacity: 0;
    transform: scale(0);
    visibility: hidden;
  }
}
