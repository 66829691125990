@use '@assets/scss/color';

.loading {
    width: 32px;
    height: 32px;
    background-color: unset;
    border-radius: 50%;
    border: 4px solid color.$black-color;
    border-top: 4px solid color.$white-color;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}