@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

@mixin five-column-template {
  $unit-percent: 12%;

  display: grid;
  grid-template-columns: auto $unit-percent ($unit-percent * 1.5) $unit-percent (
      $unit-percent * 1.5
    );
}

@mixin four-column-template {
  $unit-percent: 15%;

  display: grid;
  grid-template-columns: auto ($unit-percent * 1.5) $unit-percent (
      $unit-percent * 1.5
    );
}

.row {
  &.num5Columns {
    @include five-column-template();
  }

  &.num4Columns {
    @include four-column-template();
  }
}

$table_header_height: 40px;

.header {
  border: 1px solid color.$black-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: $table_header_height;

  .cell {
    background-color: color.$light-blue-color;
    color: color.$blue-darker-color;
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0 4px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &:first-child {
      align-items: flex-start;
      padding-left: 16px;
    }

    &:last-child,
    &:nth-last-child(3) {
      text-align: right;
      align-items: flex-end;
      padding-right: 16px;
    }

    &:not(:last-child) {
      border-right: 1px solid color.$black-color;
    }
  }
}

.body {
  max-height: calc(
    100vh -
      (
        variable.$nav_height + 40px + variable.$tabHeight +
          $table_header_height + variable.$container_header_height_m +
          variable.$container_footer_height + 40px
      )
  );
  overflow: auto;
  border-bottom: 1px solid color.$black-color;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .cell {
      font-size: 1.4rem;
    }
  }

  .body {
    max-height: calc(
      100vh -
        (
          variable.$nav_height + 40px + variable.$tabHeight +
            variable.$table_header_height +
            variable.$container_header_height_mobile_m +
            variable.$container_footer_height + 40px
        )
    );
  }
}
