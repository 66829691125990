@use '@assets/scss/color';
@use '@assets/scss/error';
@use '@assets/scss/variable';

.formContent {
    display: flex;
    align-items: flex-end;
    max-height: 48px;
    position: relative;

    &.error::before {
        @include error.arrowErrorBox;
    }

    &.error::after {
        @include error.errorBox;
    }
}

.formLabel {
    font-size: 1.4rem;
    text-align: right;
    min-width: 120px;
    margin-right: 20px;
    align-self: flex-end;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
}

.formSelect {
    padding: 5px;
    font-size: 1.4rem;
    height: 30px;
    min-width: 240px;
    border-bottom: 1px solid color.$border-color;
    outline: none;
}
