@use '@assets/scss/color';
@use '@assets/scss/error';
@use '@assets/scss/variable';

@mixin btnStyles {
  padding: 10px 20px;
  font-size: 1.6rem;
  text-decoration: none;
  color: color.$white-color;
  background-color: color.$blue-color;
  outline: none;
  border: none;
  border-radius: 5px;
  display: inline-block;
  text-transform: uppercase;
  min-width: 120px;
  text-align: center;
}

.action {
  display: flex;
  align-self: center;
  margin-right: 0;

  .toggleButtonBar {
    user-select: none;
    cursor: pointer;
    font-size: 1.8rem;

    i {
      margin-right: 4px;
    }
  }

  & .actionBtnWrapper {
    &.actionBtnHide {
      display: none;
    }

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    position: absolute;
    top: 110%;
    right: 20px;
    left: 20px;
    background-color: color.$white-color;
    border: 1px solid color.$border-color;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0px 1px color.$shadow;
    z-index: 1;
    transform-origin: top right 20px;
    animation: zoomOut ease-in-out 0.2s;

    &::after {
      content: '';
      position: absolute;
      right: 40px;
      bottom: 100%;
      border-width: 6px;
      border-color: transparent transparent color.$shadow transparent;
      border-style: solid;
    }

    & .actionBtn {
      margin-left: 8px;
      cursor: pointer;
      @include btnStyles;

      &:hover {
        opacity: 0.9;
      }

      &.inActive {
        opacity: 0.6;
        cursor: default;
      }
    }

    .listNextStatus {
      margin-left: 12px;
      background-color: color.$blue-darker-color;
      color: color.$white-color;
      outline: none;
      border-radius: 4px;
      width: 128px;
      border: none;
      z-index: 2;
    }
  }
}

@media screen and (max-width: 768px) {
  .action {
    display: none;
  }
}

@keyframes zoomOut {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
