@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  height: calc(100vh - variable.$nav_height - 80px);
  position: relative;
  padding-top: 16px;
}

@mixin column-template {
  display: grid;
  grid-template-columns: 8% 12% 15% 16% 10% 15% 15% 9%;
}

.heading {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: color.$blue-darker-color;
}

.filter {
  height: 64px;
  display: flex;
  align-items: center;

  &Select {
    margin-left: 16px;
    font-size: 1.6rem;
    outline: none;
    padding: 4px 8px;
    border: 1px solid color.$border-color;
    border-radius: 4px;
    background-color: color.$white-color;
  }
}

.table {
  border: 1px solid color.$border-color;
  border-radius: 4px;

  &Header {
    background-color: color.$light-blue-color;
    border-bottom: 1px solid color.$border-color;
    list-style: none;
    padding: 0;
    margin: 0;

    &Item {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      color: color.$blue-darker-color;
      font-weight: bold;
      text-align: center;
      padding: 8px 0;
      font-size: 1.6rem;
    }
  }

  &Body {
    padding: 0;
    margin: 0;
    max-height: calc(
      100vh - 80px - variable.$nav-height - 23px - 80px - 40px - 32px -
        16px - 41px
    );
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &Item {
      padding: 4px 0;

      &Text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;
        color: color.$text-color;
        line-height: 1.4rem;
        padding: 8px 0;

        .icon {
          padding: 4px;
          margin: 0 4px;
          cursor: pointer;
          height: 100%;

          &:hover {
            background-color: color.$light-gray-color;
          }
        }
      }

      &:nth-child(2n + 1) {
        background-color: color.$nearly-white-color;
      }

      &:not(:last-child) {
        border-bottom: 1px solid color.$border-color;
      }
    }
  }
}

.row {
  @include column-template;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 16px;
}
