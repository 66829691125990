@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.title {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: color.$black-color;
}

.container {
  &withHeader {
    margin-top: 24px;
  }

  .tableContainer {
    border-radius: 4px;
    border: 1px solid color.$border-color;
    width: 100%;
  }

  .header {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    background-color: color.$light-blue-color;
    border-bottom: 1px solid color.$border-color;
    color: color.$blue-darker-color;
    padding: 4px 0;
  }

  tr:nth-child(2n + 1) {
    background-color: color.$nearly-white-color;
  }

  .row {
    font-size: 16px;
    text-align: center;
    padding: 4px 0;
    border-bottom: 1px solid color.$border-color;
  }

  .isPC {
    display: table-cell;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .pagination {
    margin: 16px auto 0;
  }
}
