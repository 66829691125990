@use './assets/scss/color';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
    font-family: 'utm-akashi';
    src: url('./assets/font/UTM\ Akashi.ttf') format('woff'),
        url('./assets/font/UTM\ Akashi.ttf') format('opentype'),
        url('./assets/font/UTM\ Akashi.ttf') format('truetype');
}

* {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 10px;
}

@page {
    margin: 0mm;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: color.$body-background;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        'Courier New', monospace;
}

#text {
    display: none;
}

h1, h2, h3, h4, p {
    margin: 0;
}

@media (min-width: 1025px) {
    body::-webkit-scrollbar {
        display: none;
    }
}
