@use '@assets/scss/color';
@use '@assets/scss/variable';

.modalWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 2;

    &.hide {
        display: none;
    }
}

.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: color.$overlay-color;
}

.modal {
    margin: auto;
    height: 600px;
    width: 800px;
    background-color: color.$modal-background-color;
    z-index: 2;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    box-shadow: 1px 1px 16px color.$shadow;

    .closeIcon {
        position: absolute;
        top: 0;
        right: 4px;
        padding: 4px;
        font-size: 2rem;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }
    }

    .form {
        height: variable.$container_header_height_m;
        display: flex;
        align-items: flex-end;
        font-size: 1.6rem;
        padding-bottom: 12px;

        .formWrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 8px;
            position: relative;

            .formLabel {
                color: color.$label-color;
            }

            .formInput {
                border: none;
                border-bottom: 1px solid color.$border-color;
                padding: 4px;
                outline: none;
            }

            &.error::before {
                content: attr(error);
                position: absolute;
                top: 110%;
                left: 0;
                color: color.$error-text-color;
                background-color: color.$error-background-color;
                z-index: 1;
                border-radius: 4px;
                padding: 2px 8px;
                box-shadow: 1px 1px 4px color.$error-shadow-color;
            }

            &.error::after {
                content: '';
                position: absolute;
                top: 90%;
                left: 4px;
                border-style: solid;
                border-width: 8px;
                border-color: transparent transparent
                    color.$error-background-color transparent;
            }
        }

        .submitBtn {
            margin: 0 8px 0 16px;
            outline: none;
            border: none;
            text-transform: uppercase;
            padding: 4px 16px;
            border-radius: 4px;
            color: color.$btn-text-color;
            background-color: color.$btn-background-color;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
