@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
  margin: calc(40px + variable.$nav-height) 40px 0;
  background-color: color.$white-color;
  height: calc(100vh - variable.$nav-height - 80px);
  overflow: hidden;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 20% 80%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 16px #ccc;
}

.navigation {
  padding: 0;
  margin: 0;
  max-height: calc(100vh - variable.$nav-height - 80px);
  background-color: color.$nav-settings-background-color;
  list-style: none;
  display: block;

  &::-webkit-scrollbar {
    display: none;
  }

  &List {
    max-height: calc(100vh - variable.$nav-height - 80px - 72px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &Header {
    color: color.$nav-settings-text-header-color;
    text-transform: uppercase;
    font-size: 2.4rem;
    padding: 20px 20px 0;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 24px;
  }

  &Item {
    cursor: pointer;
    padding: 12px 24px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: color.$nav-settings-hover-background-color;

      .navigationContent {
        color: color.$nav-settings-hover-text-content-color;
      }
    }

    &Header {
      display: flex;
      align-items: center;

      &RightIcon {
        margin-left: auto;
      }
    }

    &Icon,
    &HeaderIcon,
    &HeaderRightIcon {
      margin-right: 12px;
      font-size: 1.6rem;
      color: color.$nav-settings-text-content-color;
    }

    &Content,
    &HeaderContent {
      color: color.$nav-settings-text-content-color;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  &ItemMenu {
    cursor: pointer;
    padding: 12px 0 12px 24px;
    text-decoration: none;

    .navigationItem {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      margin-left: 0;
      margin-top: 4px;
      padding-left: 12px;

      &Content,
      &Icon {
        font-size: 1.5rem;
      }
    }

    .navigationItems {
      margin-top: 8px;
    }
  }
}

.content {
  margin: 0 16px;
}

.selected {
  padding-left: 12px;
  padding-right: 0;
  margin-left: 12px;
  background-color: color.$nav-selected-settings-hover-background-color;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  .navigationItemIcon,
  .navigationItemContent,
  .navigationItemHeaderIcon,
  .navigationItemHeaderContent,
  .navigationItemHeaderRightIcon {
    color: color.$nav-selected-settings-hover-text-color;
  }

  &:hover {
    background-color: color.$nav-selected-settings-hover-background-color;

    .navigationItemContent {
      color: color.$nav-selected-settings-hover-text-color;
    }
  }
}
