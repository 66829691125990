@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

@mixin column-template {
  display: grid;
  grid-template-columns: 1fr repeat(5, 2fr) 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr repeat(2, 2fr) 1fr;
  }
}

.heading {
  margin: 16px 16px 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: color.$blue-darker-color;
  font-size: 16px;
}

.filter {
  margin: 0 16px;
  height: 64px;
  display: flex;
  gap: 16px;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: unset;
    margin-bottom: 16px;
  }
  
  &Select {
    text-transform: capitalize;
    font-size: 1.6rem;
    outline: none;
    padding: 4px 8px;
    border: 1px solid color.$border-color;
    border-radius: 4px;
    background-color: color.$white-color;
  }
}

.table {
  border: 1px solid color.$border-color;
  border-radius: 4px;
  margin: 0 16px;

  @media screen and (max-width: 768px) {
    margin-bottom: 16px; 
  }

  &Header {
    background-color: color.$light-blue-color;
    border-bottom: 1px solid color.$border-color;
    list-style: none;
    padding: 0;
    margin: 0;

    &Item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: color.$blue-darker-color;
      font-weight: bold;
      text-align: center;
      padding: 8px 0;
      font-size: 1.6rem;
    }
  }

  &Body {
    padding: 0;
    margin: 0;
    max-height: calc(
      100vh - 80px - variable.$nav-height - 23px - 80px - 40px - 32px -
        16px - 64px
    );
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &Item {
      padding: 4px 0;

      &Text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;
        color: color.$text-color;
        line-height: 1.4rem;
        padding: 8px 0;

        .icon {
          padding: 4px;
          margin: 0 4px;
          cursor: pointer;
          height: 100%;
          color: color.$text-color;

          &:hover {
            background-color: color.$light-gray-color;
          }
        }
      }

      &.status-99 &Text{
        opacity: 0.8;
      }

      &:nth-child(2n + 1) {
        background-color: color.$nearly-white-color;
      }

      &:not(:last-child) {
        border-bottom: 1px solid color.$border-color;
      }
    }
  }
}

.row {
  @include column-template;
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 16px;

  @media screen and (max-width: 768px) {
    position: static; 
  }
}

.action {
  border: none;
  outline: none;
}
