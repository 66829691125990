@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  padding: 16px 0;
}

.header {
  margin-bottom: 24px;
  text-transform: uppercase;
  color: color.$blue-color;
  letter-spacing: 1px;
}

.runningPages {
  margin: 32px 0 24px;
  max-height: calc(100vh - 326px - variable.$nav_height);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &Item,
  &All {
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &Checkbox {
      margin-right: 12px;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid color.$checkbox-border-color;
      background-color: color.$checkbox-background-color;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + &Checkbox {
      position: relative;
      background-color: color.$checkbox-checked-background-color;
      border: none;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 5px;
        height: 10px;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        -ms-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
        border: solid white;
        border-width: 0 3px 3px 0;
      }
    }
  }

  &All {
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &Item {
    font-weight: normal;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.ecommerces {
  max-height: 395px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.groupContainer {
  max-height: calc(100vh - 335px);
  overflow: auto;
}
.group {
  max-height: 236px;
  padding: 8px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.inputHeader {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
.inputGroup {
  text-align: left;
  font-size: 16px;
  margin-top: 12px;

  .radioBtn {
    margin-right: 8px;
  }
}
