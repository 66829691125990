@use '@assets/scss/color';
@use '@assets/scss/error';
@use '@assets/scss/variable';

.searchWrapper {
  margin-left: 20px;
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid color.$border-color;
  border-radius: 6px;
  height: 40px;
  overflow: hidden;
  justify-self: center;

  & .searchIcon {
    position: absolute;
    right: 160px;
    padding: 8px;
    cursor: pointer;

    i {
      font-size: 1.6rem;
    }
  }

  & .searchBar {
    flex: 1;
    padding: 4px 40px 4px 8px;
    font-size: 1.6rem;
    border-radius: 5px;
    outline: none;
    border: none;
  }

  & .searchTypes {
    font-size: 1.6rem;
    width: 160px;
    padding-left: 10px;
    background-color: color.$white-color;
    border: none;
    outline: none;
    height: 40px;
  }

  &::after {
    content: '';
    height: 60%;
    position: absolute;
    top: 50%;
    right: 160px;
    transform: translateY(-50%);
    border-left: 1px solid color.$border-color;
  }
}

@media screen and (max-width: 768px) {
  .searchWrapper {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
    height: unset;
    border: unset;
    padding: 0 16px;

    & .searchBar {
      width: 100%;
      padding: 8px 40px 8px 8px;
      border: 1px solid color.$border-color;
    }

    & .searchIcon {
      right: 16px;
    }

    &::after {
      display: none;
    }

    & .searchTypes {
      width: 100%;
      padding: 8px;
      border: 1px solid color.$border-color;
      border-top: unset;
    }
  }
}
