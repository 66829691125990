@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.modal {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    &Overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);

        z-index: 50;
    }

    &Content {
        margin: auto;

        z-index: 51;
    }
}
