@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
  margin: calc(40px + variable.$nav-height) 40px 0;
  height: calc(100vh - 180px);
  background-color: color.$content-container;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 16px color.$shadow;
}

.datePicker {
  display: flex;
  align-items: center;
  height: variable.$container_header_height_m;
  margin: 0 16px;

  &Label {
    font-size: 1.4rem;
    margin-right: 8px;
    font-weight: bold;
    color: color.$text-color;
    text-transform: uppercase;
    line-height: 1.6;
  }

  .select {
    border-radius: 4px;
    border: 1px solid color.$black-color;
    padding: 4px;
    font-size: 1.4rem;
  }
}

.content {
  margin: 0 16px;
  display: grid;
  grid-template-columns: 70% 30%;

  .guide {
    padding: 12px;
    margin-left: 16px;
    border-radius: 8px;
    background-color: color.$hint-background-color;

    h3 {
      color: color.$hint-text-header;
      text-transform: uppercase;
      font-size: 2.8rem;
    }

    ol {
      margin-top: 24px;
      font-size: 1.8rem;
      line-height: 1.6;

      li:not(:first-child) {
        margin-top: 12px;
      }

      b {
        text-transform: uppercase;
      }
    }
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  height: variable.$container_footer_height;
}

@media screen and (max-width: 1024px) {
  .content {
    margin: 0;
    grid-template-columns: 100% 0%;
  }

  .datePicker {
    height: variable.$container_header_height_m;
    margin: 0 16px;
  }
}
