@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.row {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

$table_header_height: 40px;

.header {
  border: 1px solid color.$black-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: $table_header_height;

  .cell {
    background-color: color.$light-blue-color;
    color: color.$blue-darker-color;
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 0 4px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &:first-child {
      align-items: flex-start;
      padding-left: 16px;
    }

    &:last-child,
    &:nth-last-child(3) {
      text-align: right;
      align-items: flex-end;
      padding-right: 16px;
    }

    &:not(:last-child) {
      border-right: 1px solid color.$black-color;
    }
  }
}

.body {
  max-height: calc(
    100vh -
      (
        variable.$nav_height + 40px + variable.$tabHeight + $table_header_height +
          variable.$container_header_height_m +
          variable.$container_footer_height + 40px
      )
  );
  overflow: auto;
  border-bottom: 1px solid color.$black-color;

  &::-webkit-scrollbar {
    display: none;
  }

  .row {
    border-width: 0px 1px 1px;
    border-style: solid;
    border-color: color.$black-color;

    &:last-child {
      border-width: 0px 1px 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:nth-child(2n + 1) {
      background-color: color.$nearly-white-color;
    }

    .cell {
      font-size: 1.6rem;
      outline: none;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border: none;

      &:first-child {
        align-items: flex-start;
        padding-left: 16px;
      }

      &:last-child,
      &:nth-last-child(3) {
        text-align: right;
        align-items: flex-end;
        padding-right: 16px;
      }

      &:not(:last-child) {
        border-right: 1px solid color.$black-color;
      }
    }

    .input {
      font-weight: bold;
      background-color: transparent;

      &.disabled {
        font-weight: normal;
        color: color.$black-color;

        &.shouldStyle {
          color: color.$light-gray-color;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .cell {
      font-size: 1.4rem;
    }
  }

  .body {
    max-height: calc(
      100vh -
        (
          variable.$nav_height + 40px + variable.$tabHeight +
            variable.$table_header_height +
            variable.$container_header_height_mobile_m +
            variable.$container_footer_height + 40px
        )
    );
  }
}
