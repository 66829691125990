@use '@assets/scss/color';
@use '@assets/scss/variable';

.content {
    margin: calc(40px + variable.$nav-height) 40px 0;
    height: calc(100vh - variable.$nav-height - 80px);
    display: flex;
}

.formWrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 480px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 2px 2px 16px color.$shadow;
}

.resultLabel {
    font-size: 2rem;
}

.formHeader {
    height: 60px;
    position: relative;
    background-color: color.$blue-color;

    .logo {
        height: 60%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 4px;
    }

    .heading {
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        position: absolute;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        color: color.$white-color;
        font-size: 2rem;
    }
}
