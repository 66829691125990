@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.textLeft {
    text-align: left !important;
}

.container {
  padding-top: 16px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .header {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: color.$blue-color;
    letter-spacing: 1px;
  }

  .button {
    font-size: 16px;
    color: white;
    background-color: color.$blue-color;
    padding: 4px 16px;
    border-radius: 4px;
    outline: none;

    &:disabled {
      opacity: 0.2;
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
}

.content {
  display: flex;
  height: calc(100vh - 166px - variable.$nav_height);
  justify-content: space-between;

  &Left {
    position: relative;
    flex: 1;

    &Pagination {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &Right {
    width: 40%;
    margin-left: 12px;
  }
}

@mixin column-template {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.table {
  border: 1px solid color.$border-color;
  border-radius: 4px;

  &Header {
    background-color: color.$light-blue-color;
    border-bottom: 1px solid color.$border-color;
    list-style: none;
    padding: 0;
    margin: 0;

    &Item {
      color: color.$blue-darker-color;
      font-weight: bold;
      text-align: center;
      padding: 8px 0;
      font-size: 1.6rem;
    }
  }

  &Body {
    padding: 0;
    margin: 0;
    max-height: calc(100vh - 80px - variable.$nav-height - 126px - 50px);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &Item {
      text-align: center;
      padding: 12px 0;

      &Text {
        word-wrap: break-word;
        text-overflow: ellipsis;
        max-height: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        font-size: 1.4rem;
        font-weight: bold;
        color: color.$text-color;
        line-height: 20px;
      }

      .action {
        display: flex;
        align-items: center;
        justify-content: center;

        .actionIcon {
          cursor: pointer;
          font-size: 1.4rem;
          font-weight: bold;
          color: color.$text-color;

          &:first-child {
            margin-right: 20px;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }

      &:nth-child(2n + 1) {
        background-color: color.$nearly-white-color;
      }

      &:not(:last-child) {
        border-bottom: 1px solid color.$border-color;
      }
    }
  }

  .selectItem {
    background-color: transparent;
    outline: none;
    border-radius: 4px;

    &.mark {
      border: 1px solid color.$red-color;
      &:focus {
        border: 1px solid color.$red-color;
      }
    }
    &:focus {
      border: 1px solid black;
    }
  }
}

@keyframes moveUp {
  0% {
    top: 24px;
    left: 16px;
    background-color: transparent;
  }
}

.row {
  @include column-template;
}

.exclamation {
  color: color.$red-color;
  font-size: 18px;
  display: inline-block;
  margin-left: 20px;
}
