@use '@assets/scss/color';
@use '@assets/scss/variable';

.noteWrapper:not(:first-child) {
    margin-top: 8px;
}

.note {
    font-family: inherit;
    line-height: 1.6;
    color: color.$note-text-color;
    background-color: color.$white-color;
    padding: 8px;
    font-size: 1.4rem;
    box-shadow: 1px 1px 8px color.$shadow;
    border-radius: 4px;
    border: none;
    outline: none;
    height: 100px;
    width: 100%;
    z-index: 1;
    overflow: hidden;

    &.isSelected {
        position: relative;
        z-index: 3;
        cursor: pointer;

        &:focus {
            cursor: text;
        }

        &:hover {
            opacity: 1;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &:hover {
        opacity: 0.75;
    }
}

.itemActions {
    padding-left: 0;
    list-style: none;
    position: absolute;
    left: 102%;
    top: 0;
    width: 200px;
    z-index: 3;
    display: none;
    animation: moveFromLeft ease-in-out 0.2s;

    .itemAction {
        border-radius: 4px;
        background-color: rgba(100, 100, 100, 0.6);
        color: color.$white-color;
        font-size: 1.4rem;
        padding: 4px;
        cursor: pointer;
        user-select: none;

        &:hover {
            background-color: rgba(63, 63, 63, 0.6);
        }

        i {
            margin: 0 8px;
        }
    }

    &.show {
        display: block;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: color.$overlay-color;
    z-index: 2;
    display: none;
    animation: darkGrad ease-in-out 0.4s;

    &.show {
        display: block;
    }
}

@keyframes moveFromLeft {
    from {
        opacity: 0;
        left: 100%;
    }

    to {
        opacity: 1;
        left: 102%;
    }
}

@keyframes darkGrad {
    from {
        background-color: transparent;
    }

    to {
        background-color: color.$overlay-color;
    }
}
