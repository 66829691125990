.container {
  display: flex;
  justify-content: space-between;
} 

.title {
  font-size: 16px;
  font-weight: bold;
} 

.value {
  font-size: 16px;
} 

