@use '@assets/scss/color';
@use '@assets/scss/variable';

$tabHeight: 80px;

.container {
  margin: calc(40px + variable.$nav-height) 40px 0;
  height: calc(100vh - variable.$nav_height - 80px);
  background-color: color.$content-container;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 16px color.$shadow;
}

@media screen and (max-width: 1024px) {
  .container {
    margin: calc(variable.$nav_height) 0 0;
    height: calc(100vh - variable.$nav_height);
  }
}
