@use '@assets/scss/color';

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: color.$overlay-color;
  }

  display: flex;
  z-index: 1;

  .formContainer {
    background-color: color.$white-color;
    max-width: 100%;
    width: 480px;
    height: 320px;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
    z-index: 1;

    .header {
      padding: 0 20px;
      height: 60px;
      background-color: color.$blue-color;
      display: flex;
      align-items: center;
      user-select: none;

      .logo {
        height: 90%;
        padding: 4px;
        object-fit: cover;
      }

      span {
        font-size: 2rem;
        font-weight: 500;
        text-transform: uppercase;
        color: color.$white-color;
      }
    }

    .form {
      padding: 20px;
      font-size: 1.6rem;
      line-height: 1.5;
      margin-top: auto;

      .labelError {
        color: color.$red-color;
      }

      .formWrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;

        .formLabel {
          user-select: none;
        }

        .formInput {
          padding: 4px;
          outline: none;
          font-size: 1.6rem;
        }

        &.error::before {
          content: '';
          position: absolute;
          top: 80%;
          left: 10px;
          border-color: transparent transparent color.$near-white-color
            transparent;
          border-width: 8px;
          border-style: solid;
          animation: moveDown ease-in-out 0.2s;
        }

        &.error::after {
          content: attr(error);
          font-size: 1.6rem;
          position: absolute;
          top: 104%;
          left: 0;
          width: 60%;
          padding: 6px;
          background-color: color.$near-white-color;
          border-radius: 4px;
          z-index: 1;
          animation: moveDown ease-in-out 0.2s;
        }

        @keyframes moveDown {
          from {
            opacity: 0.6;
            transform: translateY(-6px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      .submitButton {
        width: 100%;
        font-size: 1.6rem;
        height: 40px;
        color: color.$white-color;
        background-color: color.$blue-color;
        border: none;
        border-radius: 4px;
        outline: none;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
