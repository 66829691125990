@use '@assets/scss/color.scss';

.container {
  position: relative;
  border: 2px solid color.$light-gray-color;
  display: inline-block;

  &:focus-within {
    border: 2px solid color.$blue-color;
    transition: border 0.3s;

    .label {
      color: color.$blue-color;
      transition: color 0.3s;
    }
  }
}

.label {
  display: block;
  position: absolute;
  background-color: white;
  top: 0;
  left: 8px;
  transform: translateY(-50%);
  font-size: 16px;
}

.select {
  padding-top: 12px;
}
