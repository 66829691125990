$nav_height: 100px;
$nav_sp_height: 85px;
$container_header_height: 200px;
$table_header_height: 80px;
$container_footer_height: 80px;
$container_header_filter_height: 40px;
// M
$container_header_height_m: 80px;
$table_header_height_m: 48px;

// Mobile
$container_header_height_mobile_m: 123px;

// Tab bar
$tabHeight: 80px;
