@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .base-container {
    margin: 140px 40px 0;
    background-color: white;
    height: calc(100vh - 100px - 80px);
    overflow: hidden;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 2px 2px 16px #ccc;
  }
}
