@use './@assets/scss/color';

.wrapper {
  padding: 24px;
  background-color: color.$white-color;
  border-radius: 8px;
  width: 148mm;
  height: 105mm;
  position: relative;
}

.wrapperRotate {
  transform: rotate(90deg);
  height: 105mm;
  margin: 21.5mm 0;
}

.previewHeaderWrapper {
  display: flex;

  .previewHeader {
    min-width: 112px;
  }

  .moreInfo {
    flex: 1;
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
    margin-top: 12px;
    text-transform: uppercase;

    .receiveNote {
      font-size: 2rem;
      font-weight: 700;
    }

    p {
      margin: 0;
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-family: 'utm-akashi';

  & span {
    color: color.$black-color;
    font-size: 2.4rem;
    font-weight: 900;
    margin-left: 2px;
    text-transform: uppercase;
  }

  & .logo {
    filter: grayscale(100%);
    height: 40px;
    object-fit: contain;
  }
}

.contactList {
  list-style: none;
  font-size: 1.4rem;
  padding: 0;
  margin: 0;

  & .contactItem {
    & span {
      margin-left: 4px;
      font-weight: 500;
    }

    &:nth-child(3) span {
      text-decoration: underline;
    }
  }
}

.receive {
  margin-top: 4px;
  display: flex;
  align-items: center;
  line-height: 28px;

  & .labelReceive {
    font-size: 2rem;
    text-decoration: underline;
    font-weight: 500;
    text-transform: uppercase;
  }

  & .price {
    max-width: 320px;
    flex: 1;
    margin-left: auto;
    padding: 2px 4px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid color.$black-color;

    & .labelPrice {
      font-size: 1.8rem;
      font-weight: 300;
      text-transform: uppercase;
      font-weight: 500;
    }

    & .totalPrice {
      font-size: 2.4rem;
      display: block;
      font-weight: 500;
    }
  }
}

.info {
  & p {
    margin: 4px 0;
    font-size: 1.6rem;
    line-height: 1.4;
    height: 20px;
    word-wrap: break-word;
  }

  & p:nth-child(3) {
    min-height: 40px;
  }
}

.shipContainer {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
}

.contentFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .product {
    font-style: italic;
    text-decoration: underline;
    font-size: 1.8rem;
    text-transform: uppercase;

    & .productLabel {
      font-weight: 400;
    }

    & .productContent {
      margin-left: 4px;
      font-weight: 500;
    }
  }

  .confirm {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
    border: 2px solid color.$black-color;
    padding: 5px;

    & p {
      margin: 0;
      line-height: 1.5;
    }
  }
}

.previewFooter {
  margin-top: 4px;
  font-size: 1.4rem;
  display: flex;
  height: 50px;

  & .previewFooterLeft {
    flex: 1;
  }
  
  & .note {
    display: inline-block;
    width: 100%;
    word-wrap: break-word;
    margin-bottom: 0;

    span {
      text-decoration: underline;
    }
  }

  .previewAndTest {
    flex-basis: 160px;
    font-size: 1.6rem;
    padding: 4px;
    align-self: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    border: 2px solid color.$black-color;
    white-space: pre-line;
  }
}

@media print {
  .wrapper {
    border-radius: 0;
  }

  html {
    margin: 0 auto;
    padding: 0;
  }
}
