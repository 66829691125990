@use '@assets/scss/color';
@use '@assets/scss/variable';

.nav.showOnPhone {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav.showOnPhone {
    display: flex;
    align-items: center;
    list-style: none;
    background-color: color.$white-color;
    padding: 16px;
    box-shadow: 2px 2px 16px color.$shadow;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    & > li:last-child {
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 100%;
      user-select: none;

      i {
        margin-right: 8px;
        font-size: 2.4rem;
        color: color.$blue-color;
      }

      .greeting {
        margin-bottom: 0;
        cursor: pointer;
        position: relative;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 1px;

        &:hover {
          opacity: 0.8;
        }
      }

      .userOptions {
        border-radius: 4px;
        list-style-type: none;
        padding-left: 0;
        position: absolute;
        top: 80px;
        right: 16px;
        width: 168px;
        background-color: color.$blue-color;
        box-shadow: -1px 1px color.$shadow;
        z-index: 2;
        transform-origin: 100% 0;
        animation: zoomOut 0.2s linear;

        &.hide {
          display: none;
        }

        .userOption {
          padding: 8px 16px 8px 0;
          text-align: right;
          z-index: 2;

          .userLink {
            display: block;
            font-size: 1.6rem;
            color: color.$white-color;
            width: 100%;
            height: 100%;
            font-weight: 400;
            text-decoration: none;
          }

          &:hover {
            background-color: color.$white-color;

            .userLink {
              color: color.$black-color;
            }
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: -20px;
          right: 0;
          width: 100%;
          height: 20px;
          background-color: transparent;
        }

        &::before {
          content: '';
          position: absolute;
          top: -16px;
          right: 8px;
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent color.$blue-color transparent;
        }
      }
    }

    .logo {
      object-fit: contain;
      border: none;
      margin-right: 20px;
      width: 48px;
    }

    .btnWrapper {
      margin-left: auto;
      padding: 16px 0 16px 16px;
      cursor: pointer;

      i {
        font-size: 2rem;
      }

      .btns {
        z-index: 2;
        position: fixed;
        top: variable.$nav_sp_height;
        right: 0;
        left: 0;

        padding: 0;
        background-color: color.$blue-color;
        box-shadow: 1px 0px 4px color.$black-color;

        li {
          .navBtn {
            color: color.$white-color;
            background-color: unset;
            text-align: right;
            padding: 12px 12px;
            margin: 0 4px 4px 0;
            text-transform: uppercase;
            position: relative;

            &.active {
              transform: none;
            }

            &:focus {
              background-color: #ccc;
            }
          }
        }

        &.hide {
          display: none;
        }
      }
    }
  }

  .navBtn {
    margin: 0 20px;
    min-width: 150px;
    padding: 16px;
    font-size: 1.6rem;
    background-color: color.$blue-color;
    color: color.$white-color;
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    outline: none;

    &:hover {
      opacity: 0.9;
      color: color.$white-color;
      transform: translateY(-4px);
    }

    &.active {
      transform: scale(110%);
      position: relative;

      &::after {
        content: '';
        border-style: solid;
        border-width: 5px;
        border-color: transparent transparent color.$blue-color transparent;
        position: absolute;
        top: 102%;
        left: 50%;
        transform: translateX(-50%);
        animation: gradShow ease-in-out 0.2s;
      }

      @keyframes gradShow {
        from {
          opacity: 0;
          transform: translateX(-50%) translateY(10px);
        }
        to {
          opacity: 1;
          transform: translateX(-50%) translateY(0);
        }
      }
    }
  }
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
