@use '@assets/scss/color';
@use '@assets/scss/error';
@use '@assets/scss/variable';

.billCount {
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: underline;

  .billCountContent {
    display: inline-block;
    font-size: 2rem;
    margin-left: 12px;
  }
}

.billCheckedCount {
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: underline;
  margin-left: 20px;

  .billCheckedCountContent {
    display: inline-block;
    font-size: 2rem;
    margin-left: 12px;
  }
}

.selectAllBtn {
  display: flex;
  align-items: center;

  & input {
    margin: -2px 4px 0 0;
  }

  & label {
    cursor: pointer;
    font-size: 1.6rem;
    user-select: none;
  }
}

@media screen and (max-width: 768px) {
  .selectAllBtn {
    display: none;
  }
}
