@use '@assets/scss/color';
@use '@assets/scss/variable';
@import '@assets/scss/media-query.scss';

$tabHeight: 80px;

.container {
  position: relative;
  margin: calc(40px + variable.$nav-height) 40px 0;
  height: calc(100vh - variable.$nav_height - 80px);
  background-color: color.$content-container;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 2px 16px color.$shadow;

  @media #{$sp} {
    margin: 100px 0;
    height: unset;
  }
}
