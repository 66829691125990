@use '@assets/scss/color.scss';

.toast {
    position: fixed;
    z-index: 3000;
    width: 400px;
    height: 100px;
    background-color: white;
    top: 32px;
    right: 32px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    animation: slideRightIn linear 0.5s;

    &__icon { 
        font-size: 2.8rem;
        margin: 12px;
    }

    &.error {
        border-left: 5px solid color.$toast_error;

        .toast__icon {
            color: color.$toast_error;
        }
    }

    &.success {
        border-left: 5px solid color.$toast_success;

        .toast__icon {
            color: color.$toast_success;
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__title {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 600;
    }

    &__desc {
        font-size: 1.6rem;
        line-height: 2rem;
        color: rgba(0, 0, 0, 0.8);
        margin-top: 4px;
        
    }

    &__close {
        font-size: 2rem;
        margin-right: 24px;
        color: rgba(0, 0, 0, 0.4);

        &:hover {
            cursor: pointer;
        }
    }
}

@keyframes slideRightIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}
