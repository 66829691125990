@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.wrapper {
  height: calc(100% - 80px);
  display: flex;
}

.productContainer {
  margin: 16px;
  border-radius: 4px;
  border: 1px solid color.$border-color;
  width: 30%;

  .searchProduct {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: none;
    outline: none;
    padding: 4px 8px;
    font-size: 1.4rem;
    border-bottom: 1px solid color.$border-color;
  }

  .productList {
    padding-top: 8px;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .productResult {
      padding: 0 8px;
      margin-bottom: 4px;
      height: calc(100% - 50px);
      max-height: calc(100% - 50px);
      overflow-y: auto;
      width: 100%;
      border-bottom: 1px solid color.$border-color;

      &::-webkit-scrollbar {
        display: none;
      }

      .productItem {
        padding: 8px;
        border-bottom: 1px solid color.$border-color;
        font-size: 1.4rem;
        color: color.$text-color;
        font-weight: bold;
        cursor: pointer;

        h3::first-letter {
          text-transform: capitalize;
        }

        &Footer {
          display: flex;
          align-items: center;

          &Price {
            min-width: 72px;
            color: color.$blue-darker-color;
          }

          &Count.hasStock {
            color: color.$red-color;
          }

          &XMark {
            margin: 0 12px;
          }
        }
      }
    }
  }
}

.invoiceContainer {
  margin: 16px;
  border-radius: 4px;
  border: 1px solid color.$border-color;
  flex: 1;

  .invoiceUser {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 4px 8px;
    font-size: 1.4rem;
    border-bottom: 1px solid color.$border-color;
    color: color.$text-color;
    background-color: color.$light-gray-color;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 2fr 2fr;
    font-size: 1.4rem;
    text-align: center;
    padding: 8px 0;
    font-weight: bold;
  }

  .invoiceDetailsHeader {
    border-bottom: 1px solid color.$border-color;
    color: color.$blue-darker-color;
    background-color: color.$light-blue-color;
  }

  .invoiceDetails {
    height: calc(100% - 26px - 42px);
  }

  .invoiceDetailsBody {
    color: color.$text-color;
    max-height: calc(100% - 60px);
    overflow: auto;

    &ItemAction {
      cursor: pointer;
    }

    &Item input {
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      text-align: center;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .row:nth-child(2n + 1) {
      background-color: color.$light-gray-color;
    }
  }

  .invoiceInfoTotal {
    position: relative;
    border-top: 1px solid color.$border-color;
    padding: 12px 8px 8px;
    font-size: 1.6rem;
    margin-bottom: 16px;
    text-align: right;
    font-weight: bold;

    &::after {
      content: attr(data-label);
      display: block;
      font-size: 1.2rem;
      color: color.$text-color;
      position: absolute;
      right: 12px;
      top: -8px;
      background-color: color.$white-color;
    }
  }
}

.invoiceInfoContainer {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-right: 16px;

  .invoiceInfoSelect {
    font-size: 1.4rem;
    padding: 4px 8px;
    outline: none;
    border: none;
    background-color: color.$white-color;
    border: 1px solid color.$border-color;
    border-radius: 4px;
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  .invoiceInfoButton {
    font-size: 1.6rem;
    text-transform: uppercase;
    padding: 8px;
    outline: none;
    border: none;
    color: color.$white-color;
    background-color: color.$blue-color;
    border: 1px solid color.$border-color;
    border-radius: 4px;
    margin-bottom: 16px;

    i {
      margin-right: 8px;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    height: calc(100% - 80px);
  }
}

.confirmText {
  font-weight: bold;

  span:first-child {
    color: color.$red-color;
    display: inline-block;
    min-width: 320px;
  }

  span:last-child {
    color: color.$blue-color;
  }
}
