@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

@mixin five-column-template {
  $unit-percent: calc(60% / (1 + 1.5 + 1 + 1.5));

  display: grid;
  grid-template-columns: auto $unit-percent ($unit-percent * 1.5) $unit-percent (
      $unit-percent * 1.5
    );
}

@mixin four-column-template {
  $unit-percent: calc(60% / (1.5 + 1 + 1.5));

  display: grid;
  grid-template-columns: auto ($unit-percent * 1.5) $unit-percent (
      $unit-percent * 1.5
    );
}

.row {
  &.num5Columns {
    @include five-column-template();
  }

  &.num4Columns {
    @include four-column-template();
  }
}

.row {
  border-width: 0px 1px 1px;
  border-style: solid;
  border-color: color.$black-color;

  &:last-child {
    border-width: 0px 1px 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:nth-child(2n + 1) {
    background-color: color.$nearly-white-color;
  }

  .cell {
    font-size: 1.6rem;
    outline: none;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border: none;

    &:first-child {
      align-items: flex-start;
      padding-left: 16px;
    }

    &:last-child,
    &:nth-last-child(3) {
      text-align: right;
      align-items: flex-end;
      padding-right: 16px;
    }

    &:not(:last-child) {
      border-right: 1px solid color.$black-color;
    }
  }

  .input {
    font-weight: bold;
    background-color: transparent;

    &.disabled {
      font-weight: normal;
      color: color.$black-color;

      &.shouldStyle {
        color: color.$light-gray-color;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .row {
    .cell {
      font-size: 1.2rem;
    }
  }
}
