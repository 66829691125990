@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: color.$white-color;
  border: 2px solid color.$border-color;
  border-radius: 4px;
  box-shadow: -1px 1px 4px color.$shadow;
  transform: translateX(calc(100% + 16px));
  display: flex;
  align-items: center;
  z-index: 5;

  &.open {
    animation: slideIn 0.6s ease-in forwards;
  }

  &.close {
    animation: fadeOut 0.6s ease-in forwards;
  }

  .content {
    display: block;
    padding: 8px 4px;
    margin-left: 8px;
    font-size: 1.6rem;
    user-select: none;
  }

  .icon {
    font-size: 1.2rem;
    color: color.$white-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 4px;
    cursor: pointer;

    &.iconAccept {
      background-color: color.$green-color;
    }

    &.iconRefuse {
      background-color: color.$red-color;
      margin-right: 8px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: 4px solid color.$red-color;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(calc(100% + 16px));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(100% + 16px));
    opacity: 0;
  }
}
