@use '@assets/scss/color';
@use '@assets/scss/variable';

.container {
    margin: calc(40px + variable.$nav-height) 40px 0;
    background-color: color.$content-container;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 2px 2px 16px color.$shadow;
    height: calc(100vh - 180px);
}

.containerHeader {
    display: flex;
    align-items: center;
    padding: 16px;

    .contentHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
    }
}

.datePickerLabel {
    font-size: 1.6rem;
    font-weight: 500;
    margin-right: 8px;
    text-transform: uppercase;
}

.profitTotal {
    margin-left: auto;
    color: color.$blue-color;
}

.btn {
    font-size: 2rem;
    padding: 8px 12px;
    margin-left: 40px;
    outline: none;
    border: none;
    border-radius: 4px;
    color: color.$btn-text-color;
    background-color: color.$btn-background-color;
    text-transform: uppercase;

    &.link {
        text-decoration: none;
    }

    &:hover {
        color: color.$white-color;
        opacity: 0.8;
    }
}

@mixin columnSize {
    grid-template-columns: 40% 20% 40%;
}

.gridWrapper {
    display: grid;
    grid-template-columns: 50% 50%;
}

.profitTotal,
.totalWrapper {
    font-size: 3.2rem;
    position: relative;

    h3 {
        display: inline-block;
        margin-right: 12px;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 2.4rem;
    }
}

.row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px 16px;
    margin-top: 32px;
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 280px);

    .total {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 16px;
        color: color.$header-receive-color;
    }

    .tableThird {
        margin-top: 32px;
        width: 96%;
        font-size: 1.6rem;

        &:first-child {
            margin-top: 16px;
        }

        .tableHeader {
            height: 48px;
            display: grid;
            font-weight: 500;
            font-size: 2rem;
            text-transform: uppercase;
            background-color: color.$header-receive-color;

            color: color.$header-text;
            user-select: none;
            @include columnSize;

            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .sortIcon {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                }
            }
        }

        .tableRow {
            display: grid;
            @include columnSize;

            .cell:first-child {
                text-align: left;
            }
        }

        .cell {
            text-align: right;
            padding: 4px;
            border: 1px solid color.$cell-border-color;
        }
    }

    &.expense {
        .total {
            color: color.$header-expense-color;
        }

        .tableThird .tableHeader {
            background-color: color.$header-expense-color;
        }
    }
}

@media screen and (max-width: 1024px) {
    .container {
        margin: calc(variable.$nav_height) 0 0;
        height: unset;
    }

    .containerHeader {
        flex-direction: column-reverse;

        .contentHeader {
            flex-direction: column;
            margin-top: 20px;
        }
    }

    .profitTotal,
    .btn {
        margin-left: 0;
    }

    .gridWrapper {
        grid-template-columns: 100%;
    }

    .total {
        flex-direction: column;
        align-items: center;

        .totalWrapper {
            h3 {
                display: inline;
            }
        }
    }

    .row {
        max-height: unset;

        .tableThird {
            .tableHeader {
                height: unset;

                .cell {
                    text-align: center;

                    i {
                        display: none;
                    }
                }
            }
        }
    }
}
