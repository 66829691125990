@use '@assets/scss/color.scss';
@use '@assets/scss/variable.scss';

.container {
  padding-top: 16px;
  height: 100%;
}

.header {
  font-size: 16px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: color.$blue-color;
  letter-spacing: 1px;
}

.content {
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  gap: 16px;

  &Header {
    display: inline-block;
    margin-left: auto;
  }

  &Left {
    position: relative;
    flex: 1;

    .tableField {
      &Small {
        padding: 4px 8px;
      }

      &Medium {
        padding: 4px 16px;
      }

      &Large {
        padding: 4px 24px;
      }
    }
  }

  .customAction {
    display: flex;
    justify-content: center;
    gap: 8px;

    button:hover {
      opacity: 0.8; 
    }
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &Right {
    width: 40%;
    margin-left: 12px;
    height: calc(100vh - 166px - variable.$nav_height);
    overflow: auto;
  }
}

.form {
  border-radius: 4px;
  border: 2px solid color.$blue-color;
  padding: 24px 20px 24px;
  position: relative;

  &::before {
    content: attr(aftercontent);
    position: absolute;
    top: 0;
    left: 12px;
    font-size: 1.4rem;
    transform: translateY(-50%);
    color: color.$blue-color;
    display: block;
    background-color: color.$white-color;
    padding: 0 4px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &Group {
    position: relative;
    border: 1px solid color.$border-color;
    border-radius: 4px;
    padding: 4px 8px;
    margin-bottom: 24px;

    &:first-child {
      margin-top: 8px;
    }

    &:nth-last-child(2) {
      margin-bottom: 12px;
    }

    &:focus-within {
      border: 1px solid color.$blue-color;
    }

    &.formLabel::after {
      content: attr(data-label);
      position: absolute;
      top: 0;
      left: 12px;
      font-size: 1.2rem;
      transform: translateY(-50%);
      color: color.$gray-color;
      display: block;
      background-color: color.$white-color;
      padding: 0 4px;
      font-weight: bold;
      text-transform: capitalize;
      animation: moveUp 0.3s ease-out;
    }
  }

  &Input {
    border: none;
    outline: none;
    width: 100%;
    padding: 4px;
    font-size: 1.6rem;
    border: none;
    background-color: color.$white-color;
  }

  &Action {
    display: flex;
    justify-content: flex-end;

    &Item {
      min-width: 120px;
      font-size: 1.4rem;
      font-weight: 500;
      padding: 12px 0;
      color: #ffffff;
      background-color: color.$button-color;
      border: none;
      border-radius: 4px;
      text-transform: uppercase;
      outline: none;

      &:first-child {
        margin-right: 12px;
      }

      &:hover {
        opacity: 0.95;
      }

      &_LowPriority {
        background-color: unset;
        color: color.$black-color;

        &:hover {
          background-color: color.$btn-low-priority-background-color;
        }
      }

      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }
}

.btn {
  &Icon {
    margin-right: 8px;
  }
}

@mixin column-template {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 2fr 1fr;
}

.table {
  position: relative;
  border: 1px solid color.$border-color;
  border-radius: 4px;

  &Header {
    background-color: color.$light-blue-color;
    border-bottom: 1px solid color.$border-color;
    list-style: none;
    padding: 0;
    margin: 0;

    &Item {
      color: color.$blue-darker-color;
      font-weight: bold;
      text-align: center;
      padding: 8px 0;
      font-size: 1.6rem;
    }
  }

  &Body {
    padding: 0;
    margin: 0;
    max-height: calc(100vh - 80px - variable.$nav-height - 126px - 50px);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &Item {
      text-align: center;
      padding: 4px 0;

      &Text,
      .actionIcon {
        font-size: 1.4rem;
        font-weight: bold;
        color: color.$text-color;
        line-height: 1.4rem;
        padding: 8px 0;
      }

      .actionIcon {
        cursor: pointer;

        &:first-child {
          margin-right: 20px;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      &:nth-child(2n + 1) {
        background-color: color.$nearly-white-color;
      }

      &:not(:last-child) {
        border-bottom: 1px solid color.$border-color;
      }
    }
  }
}

@keyframes moveUp {
  0% {
    top: 24px;
    left: 16px;
    background-color: transparent;
  }
}

.row {
  @include column-template;
}
