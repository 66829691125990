/******************************************
*  Author : Author   
*  Created On : Fri May 20 2022
*  File : NewMarketInfo.module.scss
*******************************************/
@use '@assets/scss/color';
@use '@assets/scss/variable';

.tab {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: color.$blue-darker-color;
  height: variable.$tabHeight;
  align-items: flex-end;

  &Item {
    display: flex;
    padding: 0 16px;
    align-items: center;
    height: 60%;
    margin-left: 16px;
    font-size: 1.6rem;
    border-radius: 4px 4px 0 0;
    color: color.$white-color;
    background-color: color.$blue-color;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;

    &:not(.tabActive):hover {
      color: color.$white-color;
      opacity: 0.8;
    }

    &.tabActive {
      background-color: color.$white-color;
      color: color.$black-color;
      height: 64%;
      pointer-events: none;
      border-bottom: 1px solid color.$border-color;
    }
  }
}

.hideOnPhone {
  margin-left: 8px;
}

@media screen and (max-width: 1024px) {
  .hideOnPhone {
    display: none;
  }
}
