.container {
  padding: 16px;
}

.filterContainer {
  display: flex;
  margin-bottom: 16px;
}

.contentContainer {
  height: calc(100% - 98px);
}

.columnContainer {
  overflow: auto;
  max-height: 100%;
  padding-bottom: 40px;
}

.columnTable {
  &:not(:first-child) {
    margin-top: 24px;
  }
}
