@use '@assets/scss/color';
@use '@assets/scss/error';
@use '@assets/scss/variable';

.content {
    margin: calc(40px + variable.$nav-height) 40px 0;
    display: flex;
    position: relative;
}

.preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
}

.formActionContainer {
    display: flex;
}

.copyButton {
    position: relative;
    background-color: rgb(212 22 22 / 80%);
    color: white;
    padding: 4px 16px;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 314px; 
    transition: opacity 0.3s ease-in-out;

    & span {
        display: none;
    }

    &.copiedTag span {
        position: absolute;
        top: -24px;
        right: 20px;
        display: block;
        background-color: white;
        color: black;
        font-size: 14px;
    }

    &.copiedTag::after {
        content: '';
        position: absolute;
        top: -8px;
        right: 36px;
        border-color: transparent  transparent rgba(212, 22, 22, 0.8) transparent;
        border-width: 4px;
    }

    &:active {
        opacity: 0.6;
    }
}

.imgWrapper {
    margin-top: 16px;
    background-color: color.$white-color;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.removable:hover {
        opacity: 0.8;
    }

    &.removable:hover::after {
        position: absolute;
        content: 'Nhấp đúp chuột để xóa';
        color: color.$red-color;
        font-size: 2.4rem;
    }

    .previewProduct {
        max-width: 148mm;
        max-height: 100%;
        object-fit: contain;
    }

    &.error::after {
        font-size: 1.6rem;
        content: attr(error);
        position: absolute;
        width: 240px;
        top: -2px;
        right: -50px;
        border-radius: 8px;
        padding: 4px 12px;
        background-color: color.$blue-color;
        color: color.$white-color;
        animation: fadeIn ease-in-out 0.2s;
    }

    &.error::before {
        content: '';
        position: absolute;
        border-width: 12px;
        border-style: solid;
        border-color: color.$blue-color transparent transparent
            transparent;
        top: 24px;
        right: 27%;
        animation: fadeIn ease-in-out 0.3s;
    }
}

.formContainer {
    position: relative;
    flex: 1;
    background-color: color.$white-color;
    margin-left: 20px;
    padding: 32px;
}

.formHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .formSubmitResult {
        font-size: 2.4rem;
        line-height: 40px;
        text-transform: uppercase;
        margin-left: 140px;
        color: color.$red-color;
    }

    & .formSubmitSuccess {
        display: block;
        word-wrap: break-word;
    }

    & .formSubmitError {
        display: block;
        word-wrap: break-word;
    }

    & i {
        font-size: 1.6rem;
        padding: 12px 0 12px 12px;
        display: block;
        margin-left: auto;
        cursor: pointer;
        color: color.$blue-color;

        &:hover {
            opacity: 0.8;
        }
    }
}

.form {
    border-radius: 5px;

    .loading {
        margin-left: auto;
        margin-right: 60px;
        display: inline-block;
    }
}

.row {
    width: 100%;
    margin-top: 48px;

    &.addressContainer {
        display: flex;
        align-items: center;

        .address {
            width: calc(100% - 340px);
        }
    }
}

.leftHintSide {
    width: calc(100% - 340px);
}

.formContent {
    margin: 24px 0;
    display: flex;
    align-items: flex-end;
    max-height: 48px;
    position: relative;

    &.error::before {
        @include error.arrowErrorBox;
    }

    &.error::after {
        @include error.errorBox;
    }
}

.phoneWrapper {
    margin: 0 4px;
    font-size: 1.4rem;
    height: 24px;
    line-height: 24px;
    align-self: center;
    max-width: 120px;
    position: relative;
    background-color: color.$box-color;
    border: none;
    border-radius: 2px;
    user-select: none;
    cursor: default;

    & .phoneBox {
        color: color.$white-color;
        flex: 1;
        max-width: 84px;
        height: 20px;
        line-height: 24px;
        background-color: color.$box-color;
        border: none;
        outline: none;
        margin-left: 4px;
        display: inline-block;
    }

    & .phoneRemove {
        font-size: 1.2rem;
        color: color.$white-color;
        padding: 0 8px;

        &:hover {
            opacity: 0.6;
        }

        cursor: pointer;
    }
}

.addProductBtn {
    align-self: flex-end;
    min-width: 30px;
    height: 30px;
    margin: 0 12px;
    border: none;
    background-color: color.$blue-color;
    border-radius: 50%;

    & i {
        position: relative;
        bottom: 1px;
        color: color.$white-color;
        font-size: 1.4rem;
        line-height: 30px;
    }
}

.submitBtn {
    min-width: 120px;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 12px 0;
    color: color.$white-color;
    background-color: color.$blue-color;
    border: none;
    border-radius: 4px;
    margin-left: auto;
    text-transform: uppercase;
    outline: none;

    &:hover {
        opacity: 0.9;
    }

    &.submitBtnDisabled {
        opacity: 0.2;
    }

    &.submitHide {
        display: none;
    }
}

.hintWrapper {
    font-size: 1.2rem;
    position: absolute;
    top: 80px;
    right: 0;
    color: color.$hint-text-color;
    background-color: color.$hint-background-color;
    border-radius: 4px;
    box-shadow: -2px 2px 4px color.$shadow;
    width: 340px;

    h3,
    h4 {
        margin: 8px 4px 0;
        text-transform: uppercase;
    }

    h3 {
        font-size: 1.6rem;
        color: color.$hint-text-header;
    }

    h4 {
        margin: 4px 4px 0;
        font-size: 1.4rem;
    }

    .hintOptions {
        list-style: none;
        padding: 0;

        .hintOption {
            padding: 4px 20px;
            cursor: pointer;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 12px;
                right: 12px;
                border-top: 1px solid color.$border-color;
            }

            &:last-child::after {
                display: none;
            }

            &:hover {
                background-color: color.$hint-background-color-hover;
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0.6;
        transform: scale(0%);
    }
    to {
        opacity: 1;
        transform: scale(100%);
    }
}
