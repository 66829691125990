@use '@assets/scss/color';

.btnRefreshWrapper {
  margin-left: auto;
  margin-right: 8px;
  height: 100%;
  position: relative;

  &.newData::after {
    font-size: 1.4rem;
    content: attr(newdata);
    position: absolute;
    top: -40px;
    left: -64px;
    padding: 4px 16px;
    border-radius: 4px;
    color: color.$error-text-color;
    background-color: color.$error-background-color;
    animation: zoomOut ease-in-out 0.3s;
  }

  &.newData::before {
    content: '';
    position: absolute;
    top: -12px;
    left: 24px;
    border-width: 6px;
    border-style: solid;
    border-color: color.$error-background-color transparent transparent
      transparent;
    animation: zoomOut ease-in-out 0.3s;
  }

  .btn {
    height: 100%;
    font-size: 1.6rem;
    padding: 0 24px;
    margin-left: 16px;
    color: color.$btn-text-color;
    background-color: color.$btn-background-color;
    border: none;
    border-radius: 4px;
    outline: none;
    text-transform: uppercase;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.6;
    }
  }
}

@media screen and (max-width: 768px) {
  .btnRefreshWrapper {
    margin-left: 0;

    .btn {
      display: none;
    }
  }
}
