@use '@assets/scss/color';
@use '@assets/scss/variable';

.wrapper {
    &:webkit-scrollbar {
        display: none;
    }
}

.container {
    margin-top: variable.$nav-height;
    background-color: color.$white-color;
    height: calc(100vh - variable.$nav-height);
    overflow: hidden;
}

.bodyTrangChu {
    width: 100%;
    object-fit: cover;
}
